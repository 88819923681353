import React from 'react';

import styles from './detail-view-svg.module.scss';

interface Props {
	className?: string
}

export const DetailViewSVG = (props: Props) => {
	const {
		className,
	} = props;

	return (
		<svg aria-hidden={true} className={`${styles.detailView} ${className}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 35 35">
			<rect x="5.3" y="6.3" width="5.2" height="5.2" />
			<rect x="5.3" y="14.8" width="5.2" height="5.2" />
			<rect x="5.3" y="23.3" width="5.2" height="5.2" />
			<rect x="13.7" y="8.4" width="16" height="1" />
			<rect x="13.7" y="16.9" width="16" height="1" />
			<rect x="13.7" y="25.4" width="16" height="1" />
		</svg>
	);
};
