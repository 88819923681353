import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { HelpWidgetLandingView } from '~/favorites/help-widget/help-widget-landing-view/HelpWidgetLandingView';
import { HelpWidgetStore } from '~/favorites/help-widget/HelpWidget.store';
import { onChatVisibilityChanged, useLiveChatAvailability } from '~/util/live-chat/LiveChat.utils';

interface IProps {
	store: HelpWidgetStore;
}

export const HelpWidgetLandingContainer = observer((props: IProps) => {
	const isChatAvailable = useLiveChatAvailability();

	useEffect(() => {
		onChatVisibilityChanged((visibility) => {
			props.store.handleChatVisibilityChanged(visibility);
		});
	}, [props.store.isStoreReady, props.store.isOpen]);

	return (
		<HelpWidgetLandingView
			designAssociateName={props.store.designAssociateName}
			isChatAvailable={isChatAvailable}
			onClickChat={props.store.openChat}
			onClickDesignAssociateEmail={props.store.openEmailDAForm}
			onClickEmail={props.store.openEmailForm}
			onClickPhone={props.store.trackPhoneOptionClick}
			onClickScheduleAppointment={props.store.scheduleAppointment}
		/>
	);
});
