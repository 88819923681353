import { HelpWidgetStore } from '~/favorites/help-widget/HelpWidget.store';

export enum HelpWidgetViewState {
	Appointment = 'appointment',
	Email = 'email',
	EmailConfirmation = 'emailConfirmation',
	Landing = 'landing',
}

export const helpWidgetViewHeadings: Record<HelpWidgetViewState, string> = {
	[HelpWidgetViewState.Appointment]: 'Schedule an Appointment',
	[HelpWidgetViewState.Email]: 'Send an Email',
	[HelpWidgetViewState.EmailConfirmation]: 'Thank You for Contacting Us',
	[HelpWidgetViewState.Landing]: 'Need Design Help?',
};

export const helpWidgetBackNavigationRoutes: Record<HelpWidgetViewState, HelpWidgetViewState> = {
	[HelpWidgetViewState.Appointment]: HelpWidgetViewState.Landing,
	[HelpWidgetViewState.Email]: HelpWidgetViewState.Landing,
	[HelpWidgetViewState.EmailConfirmation]: HelpWidgetViewState.Landing,
	[HelpWidgetViewState.Landing]: HelpWidgetViewState.Landing,
};

export const navToView: Record<HelpWidgetViewState, (
	store: HelpWidgetStore
) => void> = {
	[HelpWidgetViewState.Landing]: (store: HelpWidgetStore) => {
		store.setCurrentView(HelpWidgetViewState.Landing);
	},
	[HelpWidgetViewState.Email]: (store: HelpWidgetStore) => {
		store.setCurrentView(HelpWidgetViewState.Email);
	},
	[HelpWidgetViewState.EmailConfirmation]: (store: HelpWidgetStore) => {
		store.setCurrentView(HelpWidgetViewState.EmailConfirmation);
	},
	[HelpWidgetViewState.Appointment]: (store: HelpWidgetStore) => {
		store.setCurrentView(HelpWidgetViewState.Appointment);
	},
};
