/* eslint-disable import/no-duplicates */

import React, {
	createContext, useContext, ReactNode, useEffect, useRef, RefObject
} from 'react';
import { fromSnapshot, registerRootStore, SnapshotInOf } from 'mobx-keystone';
import { useRouter } from 'next/router';

// Duplicate import needed to address TypeScript's import elision
// See https://devblogs.microsoft.com/typescript/announcing-typescript-3-8/#type-only-imports-exports
import '~/favorites/list/Stores/FavoriteList.store.root';
import type { ContactUsFormStore } from '~/contact-us/Stores/contactUsForm.store';
import { ContactUsFormStoreFactory } from '~/contact-us/Stores/contactUsForm.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { FavoriteItemStore } from '~/favorites/list/Stores/FavoriteItem.store';
import { favoritesChangedAction } from '~/favorites/Actions/favoritesChanged.action';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';
import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { redirect } from '~/util/redirect';
import { FavoritesListGroupStore } from '~/favorites/list/Stores/FavoritesListGroup.store';
import { isEngage } from '~/global/global.constants';
import { MagicRecosRfkStore } from '~/components/magic-recos-rfk/Stores/MagicRecosRfk.store';
import { createProductRecoStore } from '~/product/common/Helpers/ProductRecos.init';
import { FavoritesStore } from '~/favorites/Stores/Favorites.store.root';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { useHelpWidgetStore } from '~/favorites/help-widget/HelpWidget.utils';
import { HelpWidgetStore } from '~/favorites/help-widget/HelpWidget.store';

interface FavoritesListContext {
	contactUsFormStore: ContactUsFormStore,
	meatballMenuButtonRef: RefObject<HTMLButtonElement>,
	rootStore: FavoritesListStore,
	recosStore?: MagicRecosRfkStore,
	recentlyViewedStore?: MagicRecosRfkStore,
	helpWidgetStore?: HelpWidgetStore,
}

const Context = createContext({} as FavoritesListContext);

export const FavoritesListProvider = (
	{ children, snapshot }: { children: ReactNode, snapshot: SnapshotInOf<FavoritesListStore> }
) => {
	const {
		globalStaticModel,
		featureTogglesModel,
		HREF,
		globalDynamicStore,
		globalDynamicStore: {
			hasInitiallyLoaded = false,
		},
		linkEventStore,
		magicModal,
		globalModel,
		globalDynamicModel
	} = useGlobalContext();

	const router = useRouter();
	const rootStore = fromSnapshot<FavoritesListStore>(snapshot);
	const meatballMenuButtonRef = useRef<HTMLButtonElement | null>(null);
	const magicOverlay = MagicOverlayStoreFactory.create({ minMessageDuration: 500, position: 'GLOBAL' });

	registerRootStore(rootStore);

	rootStore.setMagicOverlay(magicOverlay);
	rootStore.setItemStore(new FavoriteItemStore({}));
	rootStore.setListGroupStore(new FavoritesListGroupStore({}));
	rootStore.setFeatureTogglesModel(featureTogglesModel);
	rootStore.setGlobalDynamicStore(globalDynamicStore);
	rootStore.setGlobalStaticModel(globalStaticModel);
	rootStore.setGlobalModel(globalModel);
	rootStore.setLinkEventStore(linkEventStore);
	rootStore.setMagicModal(magicModal);
	rootStore.setHREF(HREF);
	rootStore.setRouter(router);
	rootStore.setTrackingEventsStore(new FavoritesTrackingEventsStore({}));

	const favoritesStore = new FavoritesStore({
		isLoggedIn: globalDynamicModel.isLoggedIn,
	});

	rootStore.setFavoritesStore(favoritesStore);
	rootStore.favoritesStore?.setMagicModal(magicModal);

	useEffect(() => {
		rootStore.fetchData();
		rootStore.favoritesStore?.fetchData();
	}, [hasInitiallyLoaded]);

	const helpWidgetStore = useHelpWidgetStore({
		favoritesListStore: rootStore,
	});

	helpWidgetStore.registerCloseCallback(() => {
		rootStore.fetchData();
		rootStore.favoritesStore?.fetchData();
	});

	const redirectToHomepage = () => {
		redirect('/');
	};

	useEffect(() => {
		if (favoritesChangedAction) {
			favoritesChangedAction.add(rootStore.fetchData);
			favoritesChangedAction.add(globalDynamicStore.fetchData);
		}
		if (loggedInAction) {
			// clear out the existing list to avoid snapshot mismatch errors on login
			loggedInAction.add(() => { rootStore.setList(undefined); });
			loggedInAction.add(rootStore.fetchData);
		}
		if (loggedOutAction) {
			loggedOutAction.add(redirectToHomepage);
		}
		return function () {
			if (favoritesChangedAction) {
				favoritesChangedAction.remove(rootStore.fetchData);
				favoritesChangedAction.remove(globalDynamicStore.fetchData);
			}
			if (loggedInAction) {
				loggedInAction.remove(rootStore.fetchData);
			}
			if (loggedOutAction) {
				loggedOutAction.remove(redirectToHomepage);
			}
		};
	}, []);

	const context = { globalDynamicModel, globalStaticModel, featureTogglesModel };
	let recosStore: MagicRecosRfkStore | undefined;

	if (!isEngage) {
		recosStore = createProductRecoStore('rfkid_favorites', '', context, {
			maxItems: 18,
			sku: [],
			title: 'Recommended for You',
			analytics: {
				pageId: 'rfkid_favorites',
			},
		});
	}

	const recentlyViewedStore = createProductRecoStore('rfkid_recentlyviewed_favorites', '', context, {
		maxItems: 18,
		sku: [],
		title: 'Recently Viewed',
		analytics: {
			pageId: 'rfkid_recentlyviewed_favorites',
		},
	});

	return (
		<Context.Provider value={{
			contactUsFormStore: ContactUsFormStoreFactory.create(),
			meatballMenuButtonRef,
			rootStore,
			recosStore,
			recentlyViewedStore,
			helpWidgetStore,
		}}>
			{children}
		</Context.Provider>
	);
};

export const useFavoritesListContext = () => useContext(Context);
