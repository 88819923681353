import {
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { AvailabilityMessageModel } from '~/util/messaging/availability/AvailabilityMessage.model';
import { ImageModel } from '~/util/images/Image.model';
import { modelNamespace } from '~/util/modelNamespace';
import { ReviewStarsModel } from '~/util/power-reviews/Models/ReviewStars.model';
import { StatusMessageModel } from '~/util/messaging/line-item-status/StatusMessage.model';
import { IFSMediaViewerImage } from '~/favorites/Types/Favorites.interface';

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

@model(`${modelNamespace.FAVORITES}/ListItem`)
export class FavoritesListItem extends Model({
	id: idProp.withSetter(),
	altText: prop<string | undefined>(),
	availabilityMessage: prop<AvailabilityMessageModel | undefined>().withSetter(),
	groupId: prop<string>(),
	image: prop<ImageModel | undefined>(),
	maxQuantity: prop<number | undefined>(),
	fsMediaViewerImage: prop<IFSMediaViewerImage | undefined>(),
	name: prop<string | undefined>(),
	productUrl: prop<string | undefined>(),
	quantity: prop<number | undefined>().withSetter(),
	reviewStars: prop<ReviewStarsModel | undefined>(),
	sortOrder: prop<number>(),
	subtotal: prop<number | undefined>(),
	statusMessage: prop<StatusMessageModel | undefined>(),
	unitPrice: prop<number | undefined>(),
	fakeExpireItemHref: prop<string | undefined>(),
	updateQuantityHref: prop<string | undefined>().withSetter(),
	deleteHref: prop<string | undefined>(),
	moveToCartHref: prop<string | undefined>(),
	addSharedItemToCartHref: prop<string | undefined>(),
	selected: prop<boolean>(() => false).withSetter(),
	moveFavoritesProductGroupToListHref: prop<string | undefined>(),
	shopThisRoomUrl: prop<string | undefined>(),
}) {
	get hasImage() {
		return Boolean(this.image);
	}

	get formattedUnitPrice() {
		return this.unitPrice ? formatter.format(this.unitPrice) : undefined;
	}

	get formattedSubtotal() {
		return this.subtotal ? formatter.format(this.subtotal) : undefined;
	}

	get hasProductUrl() {
		return Boolean(this.productUrl);
	}

	get showStaticQty() {
		return Boolean(!this.supportsChangingQuantity && this.quantity);
	}

	get supportsFakeExpiringItem() {
		return typeof this.fakeExpireItemHref !== 'undefined';
	}

	get supportsChangingQuantity() {
		return typeof this.updateQuantityHref !== 'undefined';
	}

	get supportsMoveToCart() {
		return typeof this.moveToCartHref !== 'undefined';
	}

	get supportsDelete() {
		return typeof this.deleteHref !== 'undefined';
	}

	get supportsAddSharedItemToCart() {
		return typeof this.addSharedItemToCartHref !== 'undefined';
	}

	get supportsSaveSharedItemToFavorites() {
		return false;
	}
}
