import React from 'react';

import { HelpWidgetTypography } from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { Field } from '~/util/formz/Components/fields/Field';
import { Button } from '~/components/Buttons/Components/Button';
import { TextareaField } from '~/util/formz/Components/fields/TextareaField';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { HelpWidgetEmailFormFields } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailForm.type';

interface IProps {
	designAssociateName?: string;
	form?: FormBuilder | null;
}

export const HelpWidgetEmailView = (props: IProps) => {
	if (!props.form) {
		return null;
	}

	const fields = props.form.fields as HelpWidgetEmailFormFields;

	const introText = props.designAssociateName
		? `Send ${props.designAssociateName} your design questions and they'll be happy to help.`
		: 'Send us your design questions and we\'ll be happy to help.';

	return (
		<div className={'tw-mb-[28px] md:tw-mb-[36px]'}>
			<HelpWidgetTypography
				className={'tw-mb-[15px]'}
				dataQa={'help-widget-email-intro-text'}
				color={'gray'}
				component={'p'}
			>
				{introText}
			</HelpWidgetTypography>
			<HelpWidgetTypography
				className={'tw-mb-[15px]'}
				color={'gray'}
				component={'p'}
			>
				All information is required.
			</HelpWidgetTypography>
			<form {...props.form.reactProps}>
				<Field field={fields.customerName} />
				<Field field={fields.customerEmail} />
				<TextareaField field={fields.yourMessage} />
				<CheckboxField field={fields.customerCC} />
				<CheckboxField field={fields.emailUpdates} />
				<Button
					data-qa={'help-widget-email-form-submit-button'}
					type={'submit'}
				>
					Submit
				</Button>
			</form>
		</div>
	);
};
