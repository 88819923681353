import {
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';

@model(`${modelNamespace.FAVORITES}/ExpiredProductGroup`)
export class ExpiredProductGroup extends Model({
	id: idProp.withSetter(),
	name: prop<string>(),
	sortOrder: prop<number>(),
}) {
}
