import React from 'react';

import type { GetServerSidePropsContext } from 'next';
import type { AxiosPromise, AxiosResponse } from 'axios';
import {
	model, Model, prop, idProp, modelFlow, _async, _await, modelAction, getSnapshot, applySnapshot,
} from 'mobx-keystone';
import { NextRouter } from 'next/router';
import { observable } from 'mobx';

import axios from 'axios';

import {
	FAVORITES_LIST_TYPE,
	IFavoritesListGroup,
	IFavoritesListItem,
	isFavoritesDetail,
	isFavoritesPhotoDetail,
	type FavoritesDetailResponse,
	type IAnalyticsCart,
	type IAnalyticsProduct,
	type IFavoritesDetail,
	type IFavoritesPhotoDetail
} from '~/favorites/Types/Favorites.interface';
import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { modelNamespace } from '~/util/modelNamespace';
import { getAxios } from '~/global/app-config/ipHeaders';
import { setCookieHeader } from '~/util/setCookieHeader';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { ImageModel } from '~/util/images/Image.model';
import { FavoritesListDetail } from '~/favorites/list/Models/FavoritesListDetail.model';
import { createFavoriteListGroups } from '~/favorites/list/Factories/FavoriteListGroup.factory';
import { FavoriteItemStore } from '~/favorites/list/Stores/FavoriteItem.store';
import { UpdateListNotesStore } from '~/favorites/update-notes/Stores/UpdateListNotes.store';
import { RenameListStore } from '~/favorites/rename-list/Stores/RenameList.store';
import { AddDesignPresentationStore } from '~/favorites/add-design-presentation/Stores/AddDesignPresentation.store';
import { BreadcrumbModel, DynamicBreadcrumbModelFactory } from '~/layout/Models/Breadcrumb.model';
import { Button } from '~/components/Buttons/Components/Button';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';
import { LIST_VIEWS, type ListViewValue } from '~/favorites/list/Components/ListViewsToolbar';
import { FavoritesListGroupStore } from '~/favorites/list/Stores/FavoritesListGroup.store';
import { createExpiredProductGroups } from '~/favorites/list/Factories/ExpiredProductGroup.factory';
import { FavoritesStore } from '~/favorites/Stores/Favorites.store.root';
import { FavoritesListSummary } from '~/favorites/Models/FavoritesListSummary.model';
import { noop } from '~/util/noop';
import { redirect } from '~/util/redirect';
import { s7ImagePath } from '~/global/global.constants';

@model(`${modelNamespace.FAVORITES}/FavoritesListRootStore`)
export class FavoritesListStore extends Model({
	id: idProp,
	favoritesListAPILink: prop<string>('/api/favorites'),
	itemStore: prop<FavoriteItemStore | undefined>().withSetter(),
	listGroupStore: prop<FavoritesListGroupStore | undefined>().withSetter(),
	error: prop<PromiseError | null>(null).withSetter(),
	list: prop<FavoritesListDetail | undefined>().withSetter(),
	listNotesStore: prop<UpdateListNotesStore | undefined>().withSetter(),
	renameListStore: prop<RenameListStore | undefined>().withSetter(),
	addDesignPresentationStore: prop<AddDesignPresentationStore | undefined>().withSetter(),
	trackingEventsStore: prop<FavoritesTrackingEventsStore | undefined>().withSetter(),
	listView: prop<ListViewValue>(() => LIST_VIEWS.LARGE),
	listType: prop<FAVORITES_LIST_TYPE | undefined>().withSetter(),
	shareMode: prop<boolean>(false),
	favoritesStore: prop<FavoritesStore | null>(null).withSetter(),
	usingFullScreenMediaViewer: prop<boolean>(false).withSetter(),
}) {
	@observable.ref breadcrumbModels!: BreadcrumbModel[];

	featureTogglesModel: any;

	magicOverlay: any;

	globalModel: any;

	globalDynamicStore: any;

	globalStaticModel: any;

	magicModal: any;

	HREF: any;

	linkEventStore: any;

	router: NextRouter | undefined;

	onInit() {
		this.updateBreadcrumbs();
		// @ts-ignore
		global.store = this;
	}

	get validListsToMoveItemTo(): FavoritesListSummary[] {
		if (!this.favoritesStore?.lists) {
			return [];
		}

		// eslint-disable-next-line max-len
		return this.favoritesStore.lists.reduce((validLists: FavoritesListSummary[], currentList): FavoritesListSummary[] => {
			if (currentList.id === this.list?.id) {
				return validLists;
			}

			return [currentList, ...validLists];
		}, []);
	}

	@modelFlow
	fetchData = _async(function* (
		this: FavoritesListStore,
		pageProps?: AppConfigPageProps | undefined,
		ctx?: GetServerSidePropsContext | undefined
	) {
		const dynaAxios = getAxios(ctx);
		const headers = setCookieHeader(pageProps);

		try {
			const promise: AxiosPromise = dynaAxios.request({
				url: this.favoritesListAPILink,
				method: 'get',
				...(headers) && { headers },
			});

			const response: AxiosResponse<FavoritesDetailResponse> = yield* _await(promise);

			if (isFavoritesDetail(response.data)) {
				this.processList(response.data);
			}

			if (isFavoritesPhotoDetail(response.data) && this.featureTogglesModel.isOn('FAVORITE_PHOTOS')) {
				this.processPhotosList(response.data);
			}

			this.updateBreadcrumbs();

			return response.data;
		} catch (error: any) {
			if (error?.response?.status !== 404) {
				console.error(error);
			}
		}
		return {};
	});

	@modelFlow
	deleteList = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			deleteListHref,
			supportsDeletingList = false,
		} = list || {};

		if (supportsDeletingList) {
			let mainPromiseResolver: (value: unknown) => void = noop;
			try {
				this.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: deleteListHref,
					method: 'delete',
				});

				yield* _await(promise);

				this.router?.push('/favorites');
			} catch (error: unknown) {
				mainPromiseResolver(true);
				console.error('Failed to delete list', error);
				this.showErrorModal();
				return false;
			}
		}
		return true;
	});

	@modelFlow
	deleteDesignPresentation = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			addDesignPresentationHref
		} = list || {};

		let mainPromiseResolver: (value: unknown) => void = noop;
		try {
			this.magicOverlay.startLoading(new Promise((resolve) => {
				mainPromiseResolver = resolve;
			}));

			const promise: AxiosPromise = axios.request({
				url: addDesignPresentationHref,
				method: 'delete',
			});

			yield* _await(promise);

			this.fetchData();
		} catch (error: unknown) {
			mainPromiseResolver(true);
			console.error('Failed to delete design presentation', error);
			this.showErrorModal();
		} finally {
			mainPromiseResolver(true);
		}
		return true;
	});

	@modelFlow
	removeExpiredProductGroups = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			removeExpiredProductGroupsHref,
			supportsRemovingExpiredProductGroups = false,
			expiredProductGroups = [],
		} = list || {};

		if (supportsRemovingExpiredProductGroups) {
			let mainPromiseResolver: (value: unknown) => void = noop;
			try {
				this.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: removeExpiredProductGroupsHref,
					method: 'post',
					data: {
						productGroupIds: expiredProductGroups.map(epg => epg.id),
					},
				});

				yield* _await(promise);
				mainPromiseResolver(true);
				this.fetchData();
			} catch (error: unknown) {
				mainPromiseResolver(true);
				console.error('Failed to remove expired product groups', error);
				this.showErrorModal();
			}
		}
	});

	@modelFlow
	shareList = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			shareListHref,
			supportsSharingList = false,
		} = list || {};

		if (supportsSharingList) {
			let mainPromiseResolver: (value: unknown) => void = noop;
			try {
				this.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: shareListHref,
					method: 'PUT',
				});

				const response: AxiosResponse<IFavoritesDetail> = yield* _await(promise);

				const {
					_links: {
						favoritesListShareUrl: {
							href: favoritesListShareUrl = undefined,
						} = {},
					} = {},
				} = response.data.favoritesList;

				mainPromiseResolver(true);

				// this.router?.push('/favorites');
				return favoritesListShareUrl;
			} catch (error: unknown) {
				mainPromiseResolver(true);
				console.error('Failed to share list', error);
				this.showErrorModal();
			}
		}
		return null;
	});

	@modelAction
	getUpdateListNotesStore(list: FavoritesListDetail) {
		const {
			updateNotesHref,
			notes,
		} = list;

		if (typeof updateNotesHref !== 'undefined') {
			const newListStore = new UpdateListNotesStore({
				updateListNotesAPI: updateNotesHref,
				listNotes: notes,
			});

			this.setListNotesStore(newListStore);

			const promise = new Promise((resolve, reject) => {
				newListStore.openModalResolve = resolve;
				newListStore.openModalReject = reject;
			});

			promise.then(() => {
				this.setListNotesStore(undefined);
				this.fetchData();
			});

			return newListStore;
		}

		return false;
	}

	@modelAction
	getRenameListStore(list: FavoritesListDetail) {
		const {
			renameListHref,
			name,
		} = list;

		if (typeof renameListHref !== 'undefined') {
			const renameListStore = new RenameListStore({
				renameListAPI: renameListHref,
				listName: name,
			});

			this.setRenameListStore(renameListStore);

			const promise = new Promise((resolve, reject) => {
				renameListStore.openModalResolve = resolve;
				renameListStore.openModalReject = reject;
			});

			promise.then(() => {
				this.setRenameListStore(undefined);
				this.fetchData();
			});

			return renameListStore;
		}

		return false;
	}

	@modelAction
	getAddDesignPresentationStore(list: FavoritesListDetail) {
		const {
			id,
			addDesignPresentationHref,
		} = list;

		if (id && addDesignPresentationHref) {
			const addDesignPresentationStore = new AddDesignPresentationStore({
				id,
				addDesignPresentationHref,
			});

			this.setAddDesignPresentationStore(addDesignPresentationStore);

			const promise = new Promise((resolve, reject) => {
				addDesignPresentationStore.openModalResolve = resolve;
				addDesignPresentationStore.openModalReject = reject;
			});

			promise.then(() => {
				this.setAddDesignPresentationStore(undefined);
				this.fetchData();
			});

			return addDesignPresentationStore;
		}

		return false;
	}

	@modelFlow
	addAllProductsToCart = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			addAllProductsCart,
			supportsAddAllProductsToCart = false,
		} = list || {};

		let addSuccessful = false;
		let mainPromiseResolver: (value: unknown) => void = noop;

		if (supportsAddAllProductsToCart) {
			try {
				this.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: addAllProductsCart,
					method: 'post',
				});

				const { data }: AxiosResponse<{
					analytics: { addedProducts: IAnalyticsProduct[], cart: IAnalyticsCart }
				}> = yield* _await(promise);
				const { analytics } = data;
				try {
					this.trackingEventsStore?.addToCart(analytics.addedProducts, analytics.cart);
					addSuccessful = true;
					mainPromiseResolver(true);
				} catch (error: unknown) {
					console.error(error);
				}
			} catch (error: unknown) {
				console.error('Failed to add list items to cart', error);
				this.showErrorModal();
				addSuccessful = false;
			}
		}
		mainPromiseResolver(true);
		return addSuccessful;
	});

	@modelFlow
	moveAllProductsToCart = _async(function* (this: FavoritesListStore, list: FavoritesListDetail) {
		const {
			moveAllProductsCart,
			supportsMoveAllProductsToCart = false,
		} = list || {};

		if (supportsMoveAllProductsToCart) {
			let mainPromiseResolver: (value: unknown) => void = noop;
			try {
				this.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: moveAllProductsCart,
					method: 'POST',
				});

				const { data }: AxiosResponse<{
					analytics: { movedProducts: IAnalyticsProduct[], cart: IAnalyticsCart }
				}> = yield* _await(promise);
				const { analytics } = data;
				try {
					this.trackingEventsStore?.moveToCart(this.list?.id || '', analytics.movedProducts, analytics.cart);
					mainPromiseResolver(true);
					redirect('/cart');
				} catch (error: unknown) {
					console.error(error);
				}
			} catch (error: unknown) {
				mainPromiseResolver(true);
				console.error('Failed to move list to cart', error);
				this.showErrorModal();
			}
		}
		return null;
	});

	@modelAction
	processList(data: IFavoritesDetail) {
		const {
			designPresentation,
			listId,
			listName,
			imageUrl,
			notes = '',
			subtotal,
			defaultList,
			sortOrder,
			itemCount,
			groups = [],
			expiredProductGroups = [],
			_links: {
				addDesignPresentation: {
					href: addDesignPresentationHref = undefined,
				} = {},
				addAllFavoriteProductsToCart: {
					href: addAllProductsCart = undefined,
				} = {},
				deleteFavoritesList: {
					href: deleteListHref = undefined,
				} = {},
				favoritesListShareUrl: {
					href: sharedFavoritesListUrl = undefined,
				} = {},
				moveAllFavoriteProductsToCart: {
					href: moveAllProductsCart = undefined,
				} = {},
				removeExpiredProductGroups: {
					href: removeExpiredProductGroupsHref = undefined,
				} = {},
				renameFavoritesList: {
					href: renameListHref = undefined,
				} = {},
				shareFavoritesList: {
					href: shareListHref = undefined,
				} = {},
				updateFavoritesListNotes: {
					href: updateNotesHref = undefined,
				} = {},
			} = {},
		} = data.favoritesList;

		this.setListType(FAVORITES_LIST_TYPE.ITEM);

		const list = new FavoritesListDetail({
			id: listId,
			name: listName,
			...(imageUrl) && {
				image: new ImageModel({
					imagePath: imageUrl,
					width: 264,
					height: 135,
				}),
			},
			defaultList,
			addDesignPresentationHref,
			designPresentation,
			expiredProductGroups: createExpiredProductGroups(expiredProductGroups),
			sortOrder,
			itemCount,
			subtotal,
			notes,
			deleteListHref,
			updateNotesHref,
			renameListHref,
			removeExpiredProductGroupsHref,
			shareListHref,
			sharedFavoritesListUrl,
			groups: createFavoriteListGroups(groups, FAVORITES_LIST_TYPE.ITEM),
			moveAllProductsCart,
			addAllProductsCart,
		});
		if (this.list) {
			const snapshot = getSnapshot(list);

			// @ts-ignore
			applySnapshot(this.list, snapshot);
		} else {
			this.setList(list);
		}

		this.updateBreadcrumbs();

		if (
			this.listView === LIST_VIEWS.DETAIL &&
			this.listGroupStore &&
			this.list &&
			this.list instanceof FavoritesListDetail
		) {
			this.listGroupStore.getAvailability(this.list.groups);
		}
	}

	createPhotosListGroup(data: IFavoritesPhotoDetail): IFavoritesListGroup[] {
		const {
			listId,
			listName,
			defaultList,
			sortOrder,
			itemCount,
			photos = [],
			_links: groupLinks,
		} = data;

		const listItems: IFavoritesListItem[] = photos.map((photo) => {
			const {
				photoId,
				image,
				image: {
					altText,
					src,
				} = {},
				_links,
				sortOrder: photoSortOrder,
			} = photo;

			return {
				id: photoId,
				altText,
				imageUrl: `${s7ImagePath}/${src}`,
				sortOrder: photoSortOrder,
				fsMediaViewerImage: { ...image },
				_links,
			};
		});

		return [{
			groupId: listId,
			groupName: listName,
			defaultGroup: defaultList,
			sortOrder,
			itemCount,
			_links: groupLinks,
			listItems,
		}];
	}

	@modelAction
	processPhotosList(data: IFavoritesPhotoDetail) {
		const {
			listId,
			listName,
			imageUrl,
			defaultList,
			sortOrder,
			itemCount,
		} = data;

		this.setListType(FAVORITES_LIST_TYPE.PHOTO);

		// create a faux group to mimick the product list response
		const fauxGroup = this.createPhotosListGroup(data);

		const list = new FavoritesListDetail({
			id: listId,
			name: listName,
			...(imageUrl) && {
				image: new ImageModel({
					imagePath: imageUrl,
					width: 264,
					height: 135,
					mediumHeight: 335,
					mediumWidth: 544,
				}),
			},
			defaultList,
			itemCount,
			sortOrder,
			groups: createFavoriteListGroups(fauxGroup, FAVORITES_LIST_TYPE.PHOTO),
		});
		if (this.list) {
			const snapshot = getSnapshot(list);

			// @ts-ignore
			applySnapshot(this.list, snapshot);
		} else {
			this.setList(list);
		}

		this.updateBreadcrumbs();
	}

	updateBreadcrumbs() {
		const {
			list: {
				id,
				name: listName = 'Favorites',
			} = {},
			globalDynamicStore: {
				model: {
					isCustomerGuest = false,
				} = {},
			} = {},
		} = this;

		if (this.globalDynamicStore) {
			const guestModeCriteriaMet = isCustomerGuest && !this.featureTogglesModel.isOn('FAVORITE_PHOTOS');

			this.breadcrumbModels = DynamicBreadcrumbModelFactory.create([
				{
					title: guestModeCriteriaMet ? 'Favorites' : 'Lists',
					pageTitle: 'Favorites',
					url: guestModeCriteriaMet ? 'favorites/default' : 'favorites',
				},
				...((!guestModeCriteriaMet) && [{
					title: listName,
					pageTitle: `Favorites - ${listName}`,
					url: `favorites/${id}`,
				}]) || []
			]);
		}
	}

	@modelAction
	onError(error: PromiseError) {
		this.setError(error);
	}

	showErrorModal() {
		this.magicModal.openModal({
			id: 'favorites-error-modal',
			title: 'Changes cannot be saved',
			maxWidth: '500px',
			closeModalOnOverlayClick: false,
			showCloseButton: false,
			content: {
				children: (
					<div>
						<div className="tw-mb-4">Please click Continue to refresh, then try again.</div>
						<Button
							onClick={() => {
								window.location.reload();
							}}
						>Continue</Button>
					</div>
				),
			},
		});
	}

	@modelAction
	setListView(newListView: ListViewValue) {
		this.listView = newListView;

		if (this.listView === LIST_VIEWS.DETAIL && this.list) {
			this.listGroupStore?.getAvailability(this.list.groups);
		}
	}

	setMagicOverlay(magicOverlay: any) {
		this.magicOverlay = magicOverlay;
	}

	setFeatureTogglesModel(featureTogglesModel: any) {
		this.featureTogglesModel = featureTogglesModel;
	}

	setGlobalModel(globalModel: any) {
		this.globalModel = globalModel;
	}

	setGlobalDynamicStore(globalDynamicStore: any) {
		this.globalDynamicStore = globalDynamicStore;
	}

	setGlobalStaticModel(globalStaticModel: any) {
		this.globalStaticModel = globalStaticModel;
	}

	setMagicModal(magicModal: any) {
		this.magicModal = magicModal;
	}

	setHREF(HREF: any) {
		this.HREF = HREF;
	}

	setLinkEventStore(linkEventStore: any) {
		this.linkEventStore = linkEventStore;
	}

	setRouter(router: NextRouter) {
		this.router = router;
	}
}
