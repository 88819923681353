import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
	isPhone, isEmail, isChat, isAppointment, isDesignAssociateAppointment, isDesignAssociateEmail
} from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption.utils';
import { HelpWidgetTypography } from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography';
import { TextLink } from '~/components/Buttons/Components/TextLink';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import styles from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption.module.scss';
import { HelpWidgetContactIcon } from '~/favorites/help-widget/help-widget-contact-icon/HelpWidgetContactIcon';
import { contactOptionDataQa, contactOptionIcons, contactOptionLabels, ContactOptionVariant } from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption.constants';
import { HelpWidgetChatUnavailable } from '~/favorites/help-widget/help-widget-chat-unavailable/HelpWidgetChatUnavailable';
import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';

export type CommonContactOptionProps = {
	variant: ContactOptionVariant;
}

export type PhoneProps = CommonContactOptionProps & {
	variant: ContactOptionVariant.Phone;
	onClick: () => void;
}

export type ChatProps = CommonContactOptionProps & {
	isChatAvailable: boolean;
	variant: ContactOptionVariant.Chat;
	onClick?: () => void;
}

export type EmailProps = CommonContactOptionProps & {
	variant: ContactOptionVariant.Email;
	onClick: () => void;
}

export type AppointmentProps = CommonContactOptionProps & {
	variant: ContactOptionVariant.Appointment;
	onClick: () => void;
}

export type DesignAssociateAppointmentProps = CommonContactOptionProps & {
	variant: ContactOptionVariant.DesignAssociateAppointment;
	designAssociateName: string,
	href: string,
	onClick: () => void;
}

export type DesignAssociateEmailProps = CommonContactOptionProps & {
	variant: ContactOptionVariant.DesignAssociateEmail;
	designAssociateName: string,
	onClick: () => void;
}

export type HelpWidgetContactOptionProps = PhoneProps | ChatProps | EmailProps | AppointmentProps | DesignAssociateAppointmentProps | DesignAssociateEmailProps;

export const HelpWidgetContactOption = (props: HelpWidgetContactOptionProps) => {
	const label = contactOptionLabels[props.variant];
	const icon = contactOptionIcons[props.variant];
	const dataQa = `help-widget-contact-option-${contactOptionDataQa[props.variant]}`;

	return (
		<div className={classNames(styles.root, {
			[styles.rootTight]: isDesignAssociateAppointment(props)
		})}>
			{
				!isDesignAssociateAppointment(props) && icon && (
					<HelpWidgetContactIcon icon={icon} />
				)
			}
			<div>
				{isChat(props) && (
					<div className={classNames({
						[styles.chatIsUnavailable]: !props.isChatAvailable
					})}>
						<ConditionalWrapper
							condition={props.isChatAvailable}
							wrapper={(children: ReactNode) => (
								<TextButton
									data-qa={dataQa}
									data-tr-link-event-track={false}
									onClick={props.onClick}
								>
									<HelpWidgetTypography>{children}</HelpWidgetTypography>
								</TextButton>
							)}>
							<HelpWidgetTypography
								className={classNames({
									[styles.labelIsChatUnavailable]: !props.isChatAvailable
								})}
								dataQa={'help-widget-chat-label'}
							>
								Chat
							</HelpWidgetTypography>
						</ConditionalWrapper>
						{!props.isChatAvailable && (<HelpWidgetChatUnavailable />)}
					</div>
				)}
				{isPhone(props) && (
					<TextLink
						data-qa={dataQa}
						data-tr-link-event-track={false}
						href={'tel:+18003019720'}
						onClick={props.onClick}
					>
						<HelpWidgetTypography>{label}</HelpWidgetTypography>
					</TextLink>
				)}
				{isEmail(props) && (
					<TextButton
						data-qa={dataQa}
						data-tr-link-event-track={false}
						onClick={props.onClick}
					>
						<HelpWidgetTypography>{label}</HelpWidgetTypography>
					</TextButton>
				)}
				{isAppointment(props) && (
					<TextButton
						data-qa={dataQa}
						data-tr-link-event-track={false}
						onClick={props.onClick}
					>
						<HelpWidgetTypography>{label}</HelpWidgetTypography>
					</TextButton>
				)}
				{isDesignAssociateAppointment(props) && (
					<TextLink
						data-qa={dataQa}
						data-tr-link-event-track={false}
						href={props.href}
						onClick={props.onClick}
						target={'_blank'}
					>
						<HelpWidgetTypography>{label} {props.designAssociateName}</HelpWidgetTypography>
					</TextLink>
				)}
				{isDesignAssociateEmail(props) && (
					<TextButton
						data-qa={dataQa}
						data-tr-link-event-track={false}
						onClick={props.onClick}
					>
						<HelpWidgetTypography>{label} {props.designAssociateName}</HelpWidgetTypography>
					</TextButton>
				)}
			</div>
			{
				isDesignAssociateAppointment(props) && icon && (
					<HelpWidgetContactIcon icon={icon} />
				)
			}
		</div>
	);
};
