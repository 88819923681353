import React, { PropsWithChildren, ReactNode } from 'react';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath, s7ImagePath } from '~/global/global.constants';
import { HelpWidgetAppointmentLinkImage } from '~/favorites/help-widget/help-widget-appointment-link/HelpWidgetAppointmentLink.constants';
import styles from '~/favorites/help-widget/help-widget-appointment-link/HelpWidgetAppointmentLink.module.scss';
import { HelpWidgetTypography } from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography';

export interface IProps {
	/**
	 * The label text for the design services appointment link.
	 */
	children: ReactNode;
	/**
	 * Used for e2e/automation testing to find element.
	 */
	dataQa: string;
	/**
	 * Used to render additional description text about the design services appointment option.
	 */
	description: string;
	/**
	 * Used to render the primary heading text that is associated with the design services appointment option.
	 */
	heading: string;
	/**
	 * The path or URL to navigate to on interaction (opens in new tab).
	 */
	href: string;
	/**
	 * Used to render an image alongside the design services appointment link.
	 */
	image: HelpWidgetAppointmentLinkImage;
	/**
	 * Callback fired when link is clicked.
	 */
	onClick: () => void;
}

export const HelpWidgetAppointmentLink = (props: PropsWithChildren<IProps>) => {
	return (
		<div className={styles.root}>
			<div className={styles.imageColumn}>
				<S7Image
					alt={''}
					height={73}
					src={`${s7ImagePath}/${props.image}`}
					width={78}
				/>
			</div>
			<div className={styles.contentColumn}>
				<HelpWidgetTypography
					className={styles.heading}
					color={'gray'}
				>
					{props.heading}
				</HelpWidgetTypography>
				<HelpWidgetTypography
					color={'gray'}
					component={'div'}
				>
					{props.description}
				</HelpWidgetTypography>
				<TextLink
					data-qa={props.dataQa}
					data-tr-link-event-track={false}
					href={props.href}
					target={'_blank'}
					onClick={() => {
						props.onClick();
					}}
				>
					<span className={styles.linkLabel}>
						{props.children}
						<S7Image
							alt={''}
							className={styles.externalLinkIcon}
							height={18}
							src={`${s7ContentPath}/external_link_2`}
							width={18}
						/>
					</span>
				</TextLink>
			</div>
		</div>
	);
};
