import { useEffect, useState } from 'react';

import { ILiveChatWidget } from '~/util/live-chat/LiveChat.type';
import {
	ChatAvailability, ChatEvent, ChatMethod, ChatVisibility,
} from '~/util/live-chat/LiveChat.constants';

declare const window: {
	LiveChatWidget?: ILiveChatWidget;
} & Window;

export const useLiveChatAvailability = (): boolean => {
	const [isChatAvailable, setIsChatAvailable] = useState<boolean>(false);

	useEffect(() => {
		window.LiveChatWidget?.on(ChatEvent.Ready, (initialData) => {
			const isAvailable = initialData.state.availability === ChatAvailability.Online;

			setIsChatAvailable(isAvailable);
		});
	}, []);

	return isChatAvailable;
};

export const hideChat = (): void => {
	window.LiveChatWidget?.call(ChatMethod.Hide);
};

export const maximizeChat = (): void => {
	window.LiveChatWidget?.call(ChatMethod.Maximize);
};

export const onChatVisibilityChanged = (callback: (chatVisibility: ChatVisibility) => void): void => {
	window.LiveChatWidget?.on(ChatEvent.Ready, (initialData) => {
		callback(initialData.state.visibility);

		window.LiveChatWidget?.on(ChatEvent.VisibilityChanged, (visibilityChangedData) => {
			callback(visibilityChangedData.visibility);
		});
	});
};
