import { IContactIconSize } from '~/favorites/help-widget/help-widget-contact-icon/HelpWidgetContactIcon.type';

export enum ContactIcon {
	Appointment = 'appointment-calendar',
	Chat = 'chat',
	DesignAssociateAppointment = 'external_link_2',
	Email = 'email',
	Phone = 'phone'
}

export const contactIconSizes: Record<ContactIcon, IContactIconSize> = {
	[ContactIcon.Appointment]: {
		height: 32,
		width: 32,
	},
	[ContactIcon.Chat]: {
		height: 32,
		width: 32,
	},
	[ContactIcon.DesignAssociateAppointment]: {
		height: 18,
		width: 18,
	},
	[ContactIcon.Email]: {
		height: 30,
		width: 30,
	},
	[ContactIcon.Phone]: {
		height: 30,
		width: 30,
	},
};
