import React from 'react';
import axios from 'axios';

import { apiUrl } from '~/global/global.constants';
import { IHelpWidgetSendEmailPayload } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailForm.type';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const favoritesContactUsUrl = `${apiUrl}/api/contact-us/favorite`;

export const sendEmail = async (payload: IHelpWidgetSendEmailPayload): Promise<void> => {
	try {
		await axios.request({ url: favoritesContactUsUrl, method: 'post', data: payload });
	} catch (error) {
		console.error(error);
	}
};

export const buildFormSettings = (
	submitForm: () => void,
	processForm: (formPromise: Promise<void>) => Promise<void>,
): IFormSettings => {
	return {
		id: 'helpWidgetEmailForm',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: () => submitForm(),
						promiseHandler: (promise: Promise<void>) => processForm(promise),
					},
				}),
			],
		},
		fields: {
			customerCC: {
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
				label: {
					reactProps: {
						children: 'Send me a copy of this email.',
						className: 'tw-text-gray',

					},
				},

			},
			customerEmail: {
				control: {
					reactProps: {
						className: 'tw-w-full',
						required: true,
						type: 'email',
					},
				},
				label: {
					reactProps: {
						children: 'Email Address',
						className: 'tw-text-gray',

					},
				},
				settings: {
					validationConstraints: {
						email: {
							message: '^Please enter a valid email address.',
						},
						length: {
							maximum: 241,
						},
						presence: {
							message: '^Enter your email address',
						},
					},
				},
			},
			customerName: {
				control: {
					reactProps: {
						autoComplete: 'name',
						className: 'tw-w-full',
						maxLength: 100,
						required: true,
						type: 'text',
					},
				},
				label: {
					reactProps: {
						children: 'Name',
						className: 'tw-text-gray',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your name',
						},
					},
				},
			},
			emailUpdates: {
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
				label: {
					reactProps: {
						children: (
							<>
								Please send me email updates about products and special events. We will not share, sell or trade your email.{' '}
								<TextLink
									href={'/privacy'}
									target={'_blank'}
								>
									Privacy policy
								</TextLink>
							</>
						),
						className: 'tw-text-gray',
					},
				},
			},
			yourMessage: {
				label: {
					reactProps: {
						children: 'Your Message',
						className: 'tw-text-gray',
					},
				},
				control: {
					reactProps: {
						className: 'tw-w-full tw-h-[163px]',
						maxLength: 2000,
					},
				},
				settings: {
					plugins: [{ pluginId: 'maxlengthRemainingCountdown' }],
					validationConstraints: {
						presence: {
							message: '^Enter your message',
						},
					},
				},
			},
		},
	};
};
