import React from 'react';

import { HelpWidgetAppointmentLink } from '~/favorites/help-widget/help-widget-appointment-link/HelpWidgetAppointmentLink';
import { HelpWidgetAppointmentLinkImage, HelpWidgetAppointmentOption } from '~/favorites/help-widget/help-widget-appointment-link/HelpWidgetAppointmentLink.constants';
import { HelpWidgetTypography } from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography';
import styles from '~/favorites/help-widget/help-widget-appointment-view/HelpWidgetAppointmentView.module.scss';

interface IProps {
	onClickAppointmentOption: (option: HelpWidgetAppointmentOption) => void;
}

export const HelpWidgetAppointmentView = (props: IProps) => {
	return (
		<div className={styles.root}>
			<HelpWidgetTypography color={'gray'}>
				See the appointment options below and choose the one that fits your needs.
			</HelpWidgetTypography>
			<ul className={styles.links}>
				<li>
					<HelpWidgetAppointmentLink
						dataQa={'in-store-appointment-link'}
						description={'Try furniture and see materials up close.'}
						heading={'In-store'}
						href={'/free-design-services/appointment-location'}
						image={HelpWidgetAppointmentLinkImage.InStore}
						onClick={() => props.onClickAppointmentOption(HelpWidgetAppointmentOption.InStore)}
					>
						<span className={styles.inStoreLinkLabel}>Schedule an in-store appointment</span>
						<span className={styles.inStoreLinkLabelMobile}>Schedule an in-store <br /> appointment</span>
					</HelpWidgetAppointmentLink>
				</li>
				<li>
					<HelpWidgetAppointmentLink
						dataQa={'virtual-appointment-link'}
						description={'Connect through a video call.'}
						heading={'Virtual'}
						href={'https://roomandboard.bookingbug.com/book?storeId=0313-Customer%20Care&category=3#/services'}
						image={HelpWidgetAppointmentLinkImage.Virtual}
						onClick={() => props.onClickAppointmentOption(HelpWidgetAppointmentOption.Virtual)}
					>
						Schedule a virtual appointment
					</HelpWidgetAppointmentLink>
				</li>
				<li>
					<HelpWidgetAppointmentLink
						dataQa={'phone-appointment-link'}
						description={'Talk through your needs and get space planning ideas.'}
						heading={'Phone'}
						href={'https://roomandboard.bookingbug.com/book?storeId=0313-Customer%20Care&category=2#/services'}
						image={HelpWidgetAppointmentLinkImage.Phone}
						onClick={() => props.onClickAppointmentOption(HelpWidgetAppointmentOption.Phone)}
					>
						Schedule a phone appointment
					</HelpWidgetAppointmentLink>
				</li>
			</ul>
		</div>
	);
};
