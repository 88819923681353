import { model, Model, prop } from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';

@model(`${modelNamespace.UTIL}/Reviews/ReviewStarsModel`)
export class ReviewStarsModel extends Model({
	starCount: prop<number | undefined>(),
	reviewsCount: prop<number | undefined>(),
}) {
}
