export enum HelpWidgetAppointmentLinkImage {
	InStore = 'connect-widget-instore',
	Phone = 'connect-widget-phone',
	Virtual = 'connect-widget-virtual'
}

export enum HelpWidgetAppointmentOption {
	InStore = 'inStore',
	Phone = 'phone',
	Virtual = 'virtual'
}

export const helpWidgetAppointmentLinkEventNames: Record<HelpWidgetAppointmentOption, string> = {
	[HelpWidgetAppointmentOption.InStore]: 'schedule an in-store appointment',
	[HelpWidgetAppointmentOption.Phone]: 'schedule a phone appointment',
	[HelpWidgetAppointmentOption.Virtual]: 'schedule a virtual appointment',
};
