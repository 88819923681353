import {
	model, Model, prop, idProp, modelFlow, _async, _await, modelAction, getRoot,
} from 'mobx-keystone';
import axios, { AxiosPromise } from 'axios';
import { observable } from 'mobx';

import styles from '~/favorites/rename-list/Components/rename-list.module.scss';

import { modelNamespace } from '~/util/modelNamespace';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

interface IAddDesignPresentationFormModel {
	designPresentationUrl?: string
}

@model(`${modelNamespace.FAVORITES}/AddDesignPresentationStore`)
export class AddDesignPresentationStore extends Model({
	id: idProp,
	addDesignPresentationHref: prop<string>(),
	designPresentationUrl: prop<string | undefined>(),
}) {
	@observable hasSaveError: boolean | undefined;

	@observable isLoading: boolean | undefined;

	@observable.ref form: any;

	@observable.ref openModalResolve: ((value?: unknown) => void) | undefined = undefined;

	@observable.ref openModalReject: ((reason?: any) => void) | undefined = undefined;

	formModel: IAddDesignPresentationFormModel | undefined;

	onInit() {
		this.hasSaveError = false;
		this.isLoading = false;
		this.setupForm();
	}

	@modelAction
	setupForm() {
		this.formModel = {
			designPresentationUrl: this.designPresentationUrl,
		};

		this.form = new FormBuilder(this.formModel, this.formSettings);
	}

	get submitDisabled() {
		const {
			form: {
				plugins: {
					formValidator,
				},
			},
		} = this;

		return formValidator.hasErrors;
	}

	@modelFlow
	promiseHandler = _async(function* (this: AddDesignPresentationStore, promise: Promise<any>) {
		try {
			const rootStore: FavoritesListStore = getRoot(this);

			yield* _await(promise);

			rootStore.magicModal.closeModal();
			this.isLoading = false;
			if (typeof this.openModalResolve === 'function') {
				this.openModalResolve();
			}
		} catch (error: any) {
			console.error(error);
			this.hasSaveError = true;
			this.isLoading = false;

			if (typeof this.openModalReject === 'function') {
				this.openModalReject(error);
			}
		}
	});

	submitHandler(form: any) {
		const rootStore: FavoritesListStore = getRoot(this);

		if (rootStore.linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventName: 'save',
				trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
				trLinkEventCompName: 'add design presentation',
				trLinkEventCompType: 'modal',
			};
			rootStore.linkEventStore.trackLinkEvent(linkEventTrackingData);
		}

		form.plugins.formValidator.validateForm();
		if (!form.plugins.formValidator.hasErrors) {
			this.isLoading = true;
			this.hasSaveError = false;

			return axios.post(`/api/favorites/${this.id}/design-presentation`, {
				designPresentationUrl: form.model.designPresentationUrl,
			});
		}
		return Promise.reject('form has atleast one error');
	}

	cancel() {
		if (typeof this.openModalResolve !== 'function') {
			return;
		}
		this.openModalResolve();
	}

	get formSettings() {
		const self = this;

		return {
			id: 'add-design-presentation-form',
			reactProps: {
				method: 'put',
				className: 'formz add-design-presentation-form',
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							promiseHandler: (promise: AxiosPromise) => {
								return self.promiseHandler(promise);
							},
							submitHandler: (form: any) => {
								return self.submitHandler(form);
							},
						},
					}),
				],
			},
			fields: {
				designPresentationUrl: {
					label: {
						reactProps: {
							children: 'Floorplanner Presentation Link',
						},
					},
					control: {
						reactProps: {
							type: 'text',
							className: styles['name'],
						},
					},
					settings: {
						validationConstraints: {
							presence: {
								message: '^URL cannot be blank',
							},
							printableAscii: true,
						},
					},
				},
			},
		};
	}
}
