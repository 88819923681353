import React from 'react';
import {
	model, Model, idProp, modelFlow, _async, _await, getRoot,
} from 'mobx-keystone';
import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';

import type {
	IAnalyticsProduct,
	IAnalyticsCart,
	IFavoritesMoveItemPayload,
	IFavoritesList,
	IFavoritesMoveItemToExistingListTrackingData,
	IFavoritesMoveItemToNewListTrackingData,
	IFavoritesAddListData
} from '~/favorites/Types/Favorites.interface';
import { modelNamespace } from '~/util/modelNamespace';
import { noop } from '~/util/noop';
import { FavoritesListItem } from '~/favorites/list/Models/FavoriteListItem.model';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { favoritesChangedAction } from '~/favorites/Actions/favoritesChanged.action';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { Button } from '~/components/Buttons/Components/Button';

@model(`${modelNamespace.FAVORITES}/ItemStore`)
export class FavoriteItemStore extends Model({
	id: idProp,
}) {
	@modelFlow
	removeItem = _async(function* (this: FavoriteItemStore, item: FavoritesListItem) {
		const rootStore = getRoot<FavoritesListStore>(this);
		const {
			magicOverlay,
			trackingEventsStore,
		} = rootStore;

		let mainPromiseResolver: (value: unknown) => void = noop;

		try {
			const {
				deleteHref: removeItemAPIUrl,
			} = item;

			magicOverlay.startLoading(new Promise((resolve) => {
				mainPromiseResolver = resolve;
			}));

			const promise: AxiosPromise = axios.request({
				url: removeItemAPIUrl,
				method: 'delete',
			});

			const { data }: AxiosResponse<{
				analytics: { removedProducts: IAnalyticsProduct[] }
			}> = yield* _await(promise);
			const { analytics } = data;
			try {
				trackingEventsStore?.removeFromFavorites(rootStore?.list?.id || '', analytics.removedProducts);
			} catch (error: unknown) {
				console.error(error);
			}
			favoritesChangedAction?.dispatch();
		} catch (error: unknown) {
			console.error(error);
			rootStore.showErrorModal();
		}

		mainPromiseResolver(true);

		return {};
	});

	@modelFlow
	expireItem = _async(function* (this: FavoriteItemStore, item: FavoritesListItem) {
		const rootStore = getRoot<FavoritesListStore>(this);
		const {
			magicOverlay,
			featureTogglesModel: {
				isOn = () => false,
			} = {},
		} = rootStore;

		const {
			fakeExpireItemHref,
			supportsFakeExpiringItem,
		} = item || {};

		if (isOn('ALLOW_SIMULATING_EXPIRED_PRODUCTS') && supportsFakeExpiringItem) {
			let mainPromiseResolver: (value: unknown) => void = noop;

			try {
				magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: fakeExpireItemHref,
					method: 'put',
				});

				yield* _await(promise);

				favoritesChangedAction?.dispatch();
			} catch (error: unknown) {
				console.error(error);
				rootStore.showErrorModal();
			}

			mainPromiseResolver(true);
		}

		return {};
	});

	@modelFlow
	changeQuantity = _async(function* (this: FavoriteItemStore, newQuantity: number, item: FavoritesListItem) {
		const rootStore = getRoot<FavoritesListStore>(this);

		if (rootStore.linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventName: 'update quantity',
				trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
				trLinkEventCompName: rootStore?.list?.name,
				trLinkEventCompType: 'favorites',
			};
			rootStore.linkEventStore.trackLinkEvent(linkEventTrackingData);
		}

		try {
			const {
				updateQuantityHref: changeQuantityAPIUrl,
				quantity: oldQuantity,
				maxQuantity,
			} = item;

			if (maxQuantity && oldQuantity !== newQuantity && newQuantity <= maxQuantity) {
				let mainPromiseResolver: (value: unknown) => void = noop;

				rootStore.magicOverlay.startLoading(new Promise((resolve) => {
					mainPromiseResolver = resolve;
				}));

				const promise: AxiosPromise = axios.request({
					url: changeQuantityAPIUrl,
					method: 'put',
					data: {
						newQuantity,
					},
				});

				yield* _await(promise);
				favoritesChangedAction?.dispatch();

				mainPromiseResolver(true);
			}
		} catch (error: unknown) {
			console.error(error);
			rootStore.showErrorModal();
		}
	});

	@modelFlow
	moveToCart = _async(function* (this: FavoriteItemStore, lineItem: FavoritesListItem) {
		const rootStore = getRoot<FavoritesListStore>(this);
		let mainPromiseResolver: (value: unknown) => void = noop;
		const {
			magicOverlay,
			trackingEventsStore,
		} = rootStore;

		try {
			const {
				moveToCartHref: moveToCartAPIUrl,
			} = lineItem;

			magicOverlay.startLoading(new Promise((resolve) => {
				mainPromiseResolver = resolve;
			}));

			const promise: AxiosPromise = axios.request({
				url: moveToCartAPIUrl,
				method: 'post',
			});

			const { data }: AxiosResponse<{
				analytics: { movedProducts: IAnalyticsProduct[], cart: IAnalyticsCart }
			}> = yield* _await(promise);
			const { analytics } = data;
			try {
				trackingEventsStore?.moveToCart(rootStore?.list?.id || '', analytics.movedProducts, analytics.cart);
			} catch (error: unknown) {
				console.error(error);
			}
			favoritesChangedAction?.dispatch();
		} catch (error: any) {
			this.handleError(error);
		}
		mainPromiseResolver(true);
	});

	@modelFlow
	addSharedItemToCart = _async(function* (this: FavoriteItemStore, lineItem: FavoritesListItem) {
		const rootStore = getRoot<FavoritesListStore>(this);
		let mainPromiseResolver: (value: unknown) => void = noop;
		let addSuccessful = false;

		const {
			magicOverlay,
			trackingEventsStore,
		} = rootStore;

		try {
			const {
				addSharedItemToCartHref,
			} = lineItem;

			magicOverlay.startLoading(new Promise((resolve) => {
				mainPromiseResolver = resolve;
			}));

			const promise: AxiosPromise = axios.request({
				url: addSharedItemToCartHref,
				method: 'post',
			});

			const { data }: AxiosResponse<{
				analytics: { addedProducts: IAnalyticsProduct[], cart: IAnalyticsCart }
			}> = yield* _await(promise);
			const { analytics } = data;
			try {
				trackingEventsStore?.addToCart(analytics.addedProducts, analytics.cart);
			} catch (error: unknown) {
				console.error(error);
			}
			favoritesChangedAction?.dispatch();

			addSuccessful = true;
		} catch (error: unknown) {
			this.handleError(error);

			addSuccessful = false;
		}
		mainPromiseResolver(true);

		return addSuccessful;
	});

	handleError(error: any) {
		const rootStore = getRoot<FavoritesListStore>(this);
		const {
			response: {
				data: {
					errors = [],
				} = {},
			} = {},
		} = error;

		if (errors.length > 0) {
			const maxQuantityError = errors.find((err: any) => err.errorKey === 'maxQuantityError');

			if (maxQuantityError) {
				this.showMaxQuantityCartErrorModal();
			}
		} else {
			console.warn(error);
			rootStore.showErrorModal();
		}
	}

	showMaxQuantityCartErrorModal() {
		const rootStore = getRoot<FavoritesListStore>(this);
		rootStore.magicModal.openModal({
			id: 'favorites-error-modal',
			title: 'Changes cannot be saved',
			maxWidth: '500px',
			closeModalOnOverlayClick: false,
			showCloseButton: true,
			content: {
				children: (
					<div>
						<div className="tw-mb-4">
							The maximum order quantity of the product in the cart has been reached.
						</div>
						<Button
							onClick={() => {
								rootStore.magicModal.closeModal();
							}}
						>
							Close
						</Button>
					</div>
				),
			},
		});
	}

	@modelFlow
	moveItemToNewList = _async(function* (this: FavoriteItemStore, url: string, targetList: IFavoritesList) {
		const data: IFavoritesMoveItemPayload = {
			targetGroupId: targetList.groups[0].groupId,
			targetListId: targetList.listId
		};
		const rootStore = getRoot<FavoritesListStore>(this);

		try {
			yield* _await(axios.request({
				data,
				method: 'post',
				url,
			}));
			favoritesChangedAction?.dispatch();

			if (rootStore.linkEventStore) {
				const linkEventTrackingData: IFavoritesMoveItemToNewListTrackingData = {
					trLinkEventCompName: 'create new list',
					trLinkEventCompType: 'modal',
					trLinkEventName: 'move single item to new favorites list',
					trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
				};

				rootStore.linkEventStore.trackLinkEvent(linkEventTrackingData);
			}
		} catch (error: unknown) {
			console.error(error);
		}
	});

	@modelFlow
	moveItemToExistingList = _async(function* (
		this: FavoriteItemStore,
		targetListId: string,
		targetListUrl: string,
		moveToListUrl: string
	) {
		const rootStore = getRoot<FavoritesListStore>(this);

		try {
			const response: AxiosResponse<IFavoritesAddListData> = yield* _await(axios.request({
				method: 'get',
				url: targetListUrl
			}));

			if (!response.data.favoritesList) {
				return;
			}

			const data: IFavoritesMoveItemPayload = {
				targetGroupId: response.data.favoritesList.groups[0].groupId,
				targetListId,
			};

			yield* _await(axios.request({
				data,
				method: 'post',
				url: moveToListUrl,
			}));
			favoritesChangedAction?.dispatch();

			if (rootStore.linkEventStore) {
				const linkEventTrackingData: IFavoritesMoveItemToExistingListTrackingData = {
					trLinkEventCompName: rootStore.list?.name || '',
					trLinkEventCompType: 'favorites',
					trLinkEventName: 'move single item to new favorites list',
					trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
				};

				rootStore.linkEventStore.trackLinkEvent(linkEventTrackingData);
			}
		} catch (error: unknown) {
			console.error(error);
		}
	});
}
