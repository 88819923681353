import React from 'react';

import styles from '~/favorites/list/Components/large-tiles-svg.module.scss';

interface Props {
	className?: string
}

export const LargeTilesSVG = (props: Props) => {
	const {
		className,
	} = props;

	return (
		<svg aria-hidden={true} className={`${styles['large-tiles']} ${className}`} overflow="visible" viewBox="-1 -1 22 23" xmlns="http://www.w3.org/2000/svg">
			<path className={styles['outline']} d="M0 0h20v21H0V0Z" vectorEffect="non-scaling-stroke" />
			<path className={styles['solid']} d="M6 3a2 2 0 0 1 0 4 2 2 0 0 1 0-4Z" vectorEffect="non-scaling-stroke" />
			<path className={styles['solid']} d="M6.244 2.316 8.442 5.87 12.072 0l6.802 11h-18z" transform="translate(.126 8)" vectorEffect="non-scaling-stroke" />
		</svg>
	);
};
