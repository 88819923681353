import {
	model, Model, _async, modelFlow, _await, prop, modelAction,
} from 'mobx-keystone';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { modelNamespace } from '~/util/modelNamespace';
import { IHelpWidgetEmailForm, IHelpWidgetSendEmailPayload } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailForm.type';
import { buildFormSettings, sendEmail } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailForm.utils';
import { bootstrapLinks } from '~/global/bootstrapLinks.constants';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { publicSitePath } from '~/global/global.constants';

@model(`${modelNamespace.FAVORITES}/HelpWidgetEmailForm`)
export class HelpWidgetEmailFormStore extends Model({
	favoritesListId: prop<string>(),
	favoriteUrl: prop<string>('').withSetter(),
	userName: prop<string | undefined>(),
}) {
	form: FormBuilder | null = null;

	processFormCallbackRef: (() => void) | null = null;

	get formData(): IHelpWidgetEmailForm {
		const defaultHelpWidgetEmailFormData: IHelpWidgetEmailForm = {
			customerCC: true,
			customerEmail: this.userName || '',
			customerName: '',
			emailUpdates: false,
			yourMessage: '',
		};

		return (this.form?.model as IHelpWidgetEmailForm | undefined) || defaultHelpWidgetEmailFormData;
	}

	submitForm = async (): Promise<void> => {
		if (this.form?.plugins?.formValidator?.hasErrors) {
			return Promise.reject();
		}

		const payload: IHelpWidgetSendEmailPayload = {
			customerCC: this.formData.customerCC,
			customerEmail: this.formData.customerEmail,
			customerName: this.formData.customerName,
			emailDa: Boolean(this.userName),
			emailUpdates: this.formData.emailUpdates,
			favoriteUrl: this.favoriteUrl,
			yourMessage: this.formData.yourMessage,
		};

		return sendEmail(payload);
	};

	buildForm = (): void => {
		const formSettings = buildFormSettings(this.submitForm, this.processForm);

		this.form = new FormBuilder(this.formData, formSettings);
	};

	registerProcessFormCallback(callback: () => void): void {
		this.processFormCallbackRef = callback;
	}

	onInit(): void {
		this.buildForm();
	}

	@modelFlow
	processForm = _async(function* (this: HelpWidgetEmailFormStore, formPromise: Promise<void>) {
		yield* _await(formPromise);

		this.processFormCallbackRef?.();
	});

	@modelFlow
	getFavoriteUrl = _async(function* (this: HelpWidgetEmailFormStore) {
		try {
			const favoritesListAPILink = `${bootstrapLinks.FAVORITES.entry}/${this.favoritesListId}`;
			const favoritesListStore = new FavoritesListStore({ favoritesListAPILink });

			yield* _await(favoritesListStore.fetchData());

			const relativeFavoriteUrl = favoritesListStore.list?.sharedFavoritesListUrl;
			const favoriteUrl = relativeFavoriteUrl ? `${publicSitePath}${relativeFavoriteUrl}` : '';

			this.setFavoriteUrl(favoriteUrl);
		} catch (error) {
			console.error(error);
		}
	});

	@modelAction
	updateFavoriteUrl = (url?: string): void => {
		const relativeFavoriteUrl = url;
		const favoriteUrl = relativeFavoriteUrl ? `${publicSitePath}${relativeFavoriteUrl}` : '';

		this.setFavoriteUrl(favoriteUrl);
	};
}

export const createHelpWidgetEmailFormStore = (favoritesListId: string, userName?: string): HelpWidgetEmailFormStore => {
	const store = new HelpWidgetEmailFormStore({ favoritesListId, userName: userName || '' });

	return store;
};
