import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import {
	Model,
	_async,
	_await,
	idProp,
	model,
	modelAction,
	modelFlow,
} from 'mobx-keystone';

import { FAVORITES_LIST_TYPE, IFavoritesListGroupAvailabilityResponse } from '~/favorites/Types/Favorites.interface';
import { FavoritesListGroup } from '~/favorites/list/Models/FavoriteListGroup.model';
import { AvailabilityMessageModel } from '~/util/messaging/availability/AvailabilityMessage.model';
import { modelNamespace } from '~/util/modelNamespace';
import { FavoritesListItem } from '~/favorites/list/Models/FavoriteListItem.model';

@model(`${modelNamespace.FAVORITES}/ListGroupStore`)
export class FavoritesListGroupStore extends Model({
	id: idProp,
}) {
	@modelFlow
	getGroupAvailability = _async(function* (this: FavoritesListGroupStore, group: FavoritesListGroup) {
		try {
			const {
				getAvailabilityHref,
			} = group;

			if (getAvailabilityHref) {
				const promise: AxiosPromise = axios.request({
					url: getAvailabilityHref,
					method: 'post',
					data: {
						productGroupIds: group.groupIds,
					},
				});

				const { data }: AxiosResponse<IFavoritesListGroupAvailabilityResponse> = yield* _await(promise);
				data.availability.forEach((availability) => {
					const {
						productGroupId,
						message,
					} = availability;

					const matchedItem = group.items.find(item => item.id === productGroupId);
					if (matchedItem && message) {
						matchedItem.setAvailabilityMessage(new AvailabilityMessageModel(message));
					}
				});

				return promise;
			}
		} catch (error: unknown) {
			console.error(error);
		}

		return Promise.reject();
	});

	@modelFlow
	getAvailability = _async(function* (this: FavoritesListGroupStore, groups: FavoritesListGroup[]) {
		try {
			const promises = groups.map((group) => {
				return this.getGroupAvailability(group);
			});

			yield* _await(Promise.allSettled(promises));
		} catch (error: unknown) {
			console.error(error);
		}
	});

	@modelFlow
	updateGroupSort = _async(function* (newSort, groupSortHref: string, listType: FAVORITES_LIST_TYPE) {
		try {
			if (groupSortHref) {
				const data: Record<string, any> = {};
				if (listType === FAVORITES_LIST_TYPE.ITEM) {
					data['productGroupIdToSort'] = newSort;
				} else if (listType === FAVORITES_LIST_TYPE.PHOTO) {
					data['photoIdToSort'] = newSort;
				}

				const promise: AxiosPromise = axios.request({
					url: groupSortHref,
					method: 'put',
					data,
				});
				yield* _await(promise);
			}
		} catch (error: unknown) {
			console.error(error);
		}
	});

	@modelAction
	handleSort(group: FavoritesListGroup, sortOrder: number, direction: 'forward' | 'backward'): undefined {
		const { groupSortHref } = group;

		const itemToMove = group.items.find(item => item.sortOrder === sortOrder);

		if (!itemToMove || !groupSortHref) {
			return;
		}

		const itemToMoveIndex = group.items.indexOf(itemToMove);

		let newSort = {};

		if (direction === 'forward') {
			newSort = this.sortForward(itemToMoveIndex, group);
		}
		if (direction === 'backward') {
			newSort = this.sortBackward(itemToMoveIndex, group);
		}

		this.updateGroupSort(newSort, groupSortHref, group.listType);
	}

	@modelAction
	sortBackward(index: number, group: FavoritesListGroup) {
		if (index > 0) {
			const newIndex = index - 1;
			group.items.splice(newIndex, 0, group.items.splice(index, 1)[0]);
		}

		return this.generateNewSortOrder(group.items);
	}

	@modelAction
	sortForward(index: number, group: FavoritesListGroup) {
		if (index < group.items.length - 1) {
			const newIndex = index + 1;
			group.items.splice(newIndex, 0, group.items.splice(index, 1)[0]);
		}

		return this.generateNewSortOrder(group.items);
	}

	generateNewSortOrder(items: FavoritesListItem[]): Record<string, number> {
		const newSort: Record<string, number> = {};

		// we need the sort order to be in decending order
		const arrayLen = items.length - 1;

		items.forEach((item, index) => {
			newSort[item.id] = arrayLen - index;
		});
		return newSort;
	}
}
