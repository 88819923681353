import React from 'react';

import styles from '~/favorites/list/Components/small-tiles-svg.module.scss';

interface Props {
	className?: string
}

export const SmallTilesSVG = (props: Props) => {
	const {
		className,
	} = props;

	return (
		<svg aria-hidden={true} className={`${styles['small-tiles']} ${className}`} overflow="visible" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
			<path transform="translate(0 3)" d="M8 3h8.235v9.263H8V3Zm11.765 0H28v9.263h-8.235V3ZM8 15.737h8.235V25H8v-9.263Zm11.765 0H28V25h-8.235v-9.263Z" vectorEffect="non-scaling-stroke" />
		</svg>
	);
};
