import React, { ElementType, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography.module.scss';

export interface IProps {
	/**
	 * Text to display.
	 */
	children: ReactNode;
	/**
	 * Used to override default styles at implementation if needed.
	 */
	className?: string;
	/**
	 * Used for e2e/automation testing to find element.
	 */
	dataQa?: string;
	/**
	 * Used to control the text color at implementation.
	 */
	color?: 'gray';
	/**
	 * By default, component renders as a `p` element. You can control the underlying element type via
	 * the `component` prop.
	 */
	component?: ElementType;
}

export const HelpWidgetTypography = (props: PropsWithChildren<IProps>) => {
	const { className = '', color = '' } = props;
	const Element = props.component || 'span';

	return (
		<Element
			className={classNames({
				[styles.body]: true,
				[styles[color]]: color,
				[className]: className,
			})}
			data-qa={props.dataQa}
		>
			{props.children}
		</Element>
	);
};
