import { computed } from 'mobx';
import { model, Model, prop } from 'mobx-keystone';
import Router from 'next/router';
import { ComponentProps } from 'react';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { modelNamespace } from '~/util/modelNamespace';
import { HelpWidgetViewStateConfig, IHelpWidgetQueryParams } from '~/favorites/help-widget/HelpWidget.type';
import { ChatVisibility } from '~/util/live-chat/LiveChat.constants';
import { hideChat, maximizeChat } from '~/util/live-chat/LiveChat.utils';
import {
	HelpWidgetViewState, helpWidgetBackNavigationRoutes, helpWidgetViewHeadings, navToView,
} from '~/favorites/help-widget/HelpWidget.constants';
import { removeEmptyQueries, updateRouterQuery } from '~/util/router/router.utils';
import { buildHelpWidgetLinkEventTrackingData, buildQueryParams } from '~/favorites/help-widget/HelpWidget.utils';
import { LinkEventStore } from '~/tracking/link-event/Stores/LinkEvent.store';
import { helpWidgetAppointmentLinkEventNames, HelpWidgetAppointmentOption } from '~/favorites/help-widget/help-widget-appointment-link/HelpWidgetAppointmentLink.constants';
import { HelpWidgetLandingContainer } from '~/favorites/help-widget/help-widget-landing-container/HelpWidgetLandingContainer';
import { HelpWidgetEmailView } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailView';
import { HelpWidgetAppointmentView } from '~/favorites/help-widget/help-widget-appointment-view/HelpWidgetAppointmentView';
import { FavoritesRoute } from '~/favorites/router/router.constants';
import { getRouterQueryValue } from '~/favorites/router/router.utils';
import { createHelpWidgetEmailFormStore, HelpWidgetEmailFormStore } from '~/favorites/help-widget/help-widget-email-view/HelpWidgetEmailForm.store';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { HelpWidgetEmailConfirmation } from '~/favorites/help-widget/help-widget-email-view/help-widget-email-confirmation/HelpWidgetEmailConfirmation';
import { FavoritesListDetail } from '~/favorites/list/Models/FavoritesListDetail.model';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';

@model(`${modelNamespace.FAVORITES}/HelpWidget`)
export class HelpWidgetStore extends Model({
	chatVisibility: prop<ChatVisibility | null>(null).withSetter(),
	isDAEmail: prop<boolean>(false).withSetter(),
	isExpanded: prop<boolean>(false).withSetter(),
	isMinimized: prop<boolean>(false).withSetter(),
	isOpen: prop<boolean>(false).withSetter(),
	isMobileView: prop<boolean>(false).withSetter(),
	isStoreReady: prop<boolean>(false).withSetter(),
	view: prop<HelpWidgetViewState>(HelpWidgetViewState.Landing).withSetter(),
}) {
	viewState: ViewStateStore | null = null;

	viewStateConfig: HelpWidgetViewStateConfig = {
		[HelpWidgetViewState.Appointment]: {
			component: HelpWidgetAppointmentView,
			onEnter: (): ComponentProps<typeof HelpWidgetAppointmentView> => ({
				onClickAppointmentOption: this.trackAppointmentOptionClick,
			}),
		},
		[HelpWidgetViewState.Email]: {
			component: HelpWidgetEmailView,
			onEnter: (): ComponentProps<typeof HelpWidgetEmailView> => ({
				designAssociateName: this.isDAEmail ? this.designAssociateName : undefined,
				form: this.emailFormStore?.form,
			}),
		},
		[HelpWidgetViewState.EmailConfirmation]: {
			component: HelpWidgetEmailConfirmation,
			onEnter: (): ComponentProps<typeof HelpWidgetEmailConfirmation> => ({
				onClose: this.closeEmailConfirmation,
			}),
		},
		[HelpWidgetViewState.Landing]: {
			component: HelpWidgetLandingContainer,
			onEnter: (): ComponentProps<typeof HelpWidgetLandingContainer> => ({
				store: this,
			}),
		},
	};

	emailFormStore: HelpWidgetEmailFormStore | null = null;

	favoritesList: FavoritesListDetail | null = null;

	linkEventStore: LinkEventStore | null = null;

	featureTogglesModel: FeatureTogglesModel | null = null;

	closeCallbackRef: (() => void) | null = null;

	favoritesListStore: FavoritesListStore | null = null;

	@computed
	get designAssociateName() {
		return this.favoritesListStore?.favoritesStore?.designAssociateName;
	}

	get isChatMaximized(): boolean {
		return this.chatVisibility === ChatVisibility.Maximized;
	}

	get favoritesListId(): string {
		const route = Router.router?.route;

		if (route === FavoritesRoute.FavoritesPublic) {
			return getRouterQueryValue('listId', 'default');
		}

		return getRouterQueryValue('list', 'default');
	}

	get helpViewQueryParam(): HelpWidgetViewState {
		return getRouterQueryValue('helpView', HelpWidgetViewState.Landing);
	}

	get heading(): string {
		return helpWidgetViewHeadings[this.view];
	}

	get isBackControlVisble(): boolean {
		if (this.isEmailConfirmationView) {
			return false;
		}

		return this.view !== HelpWidgetViewState.Landing;
	}

	get isHelpWidgetOpen(): boolean {
		if (this.isChatMaximized || this.isMinimized) {
			return false;
		}

		return this.isOpen;
	}

	get shouldShowCloseControl(): boolean {
		if (this.isEmailConfirmationView) {
			return true;
		}

		return false;
	}

	get isEmailConfirmationView(): boolean {
		return this.view === HelpWidgetViewState.EmailConfirmation;
	}

	get shouldShowHelpWidget(): boolean {
		if (!this.isStoreReady) {
			return false;
		}

		if (Router.router?.route === FavoritesRoute.FavoritesPublic) {
			return false;
		}

		return true;
	}

	onInit(): void {
		this.viewState = new ViewStateStore(this, this.viewStateConfig);
	}

	close = async (): Promise<void> => {
		this.setIsExpanded(false);
		this.setIsOpen(false);

		if (this.isMobileView) {
			await this.navigateTo(FavoritesRoute.FavoritesListing);
			this.closeCallbackRef?.();
		}
	};

	closeEmailConfirmation = async (): Promise<void> => {
		this.setCurrentView(HelpWidgetViewState.Landing);
		await this.close();
	};

	registerCloseCallback(callback: () => void): void {
		this.closeCallbackRef = callback;
	}

	minimize = async (): Promise<void> => {
		if (this.view === HelpWidgetViewState.Landing) {
			this.close();

			return;
		}

		if (this.isEmailConfirmationView) {
			await this.closeEmailConfirmation();

			return;
		}

		if (this.isMobileView) {
			await this.navigateTo(FavoritesRoute.FavoritesListing, this.view);
		}

		this.setIsExpanded(false);
		this.setIsMinimized(true);
	};

	open = (): void => {
		this.setIsOpen(true);
		this.setIsExpanded(true);
		this.setIsMinimized(false);

		if (!this.isMobileView) {
			this.setCurrentView(HelpWidgetViewState.Landing);
		}
	};

	maximize = (): void => {
		this.setIsExpanded(true);
		this.setIsOpen(true);
		this.setIsMinimized(false);
	};

	maximizeMobileView = async (): Promise<void> => {
		this.setIsMobileView(true);
		await this.navigateTo(FavoritesRoute.FavoritesListingContactUs, this.helpViewQueryParam);
	};

	goBack = async (): Promise<void> => {
		if (this.isMobileView) {
			const currentHelpView = getRouterQueryValue<HelpWidgetViewState>('helpView');
			await this.navigateBackToPreviousView(currentHelpView);

			return;
		}

		this.setCurrentView(HelpWidgetViewState.Landing);
	};

	setCurrentView(helpWidgetView: HelpWidgetViewState): void {
		this.setView(helpWidgetView);
		this.viewState?.goTo<HelpWidgetViewStateConfig>(helpWidgetView);
	}

	init(
		shouldOpenHelpWidgetOnInit: boolean = false,
		isMobileView: boolean = false,
	): void {
		this.setIsMobileView(isMobileView);
		this.setHelpViewFromQueryParam(this.helpViewQueryParam);
		this.handleBackNavigation();
		this.setIsStoreReady(true);

		if (this.isMobileView && shouldOpenHelpWidgetOnInit) {
			this.open();
		}
	}

	handleBackNavigation(): void {
		if (!this.isMobileView) {
			return;
		}

		Router.router?.beforePopState(() => {
			const currentView = getRouterQueryValue<HelpWidgetViewState>('helpView');
			this.navigateBackToPreviousView(currentView);

			return false;
		});
	}

	async navigateBackToPreviousView(currentView: HelpWidgetViewState = HelpWidgetViewState.Landing): Promise<void> {
		const currentHelpView = currentView || HelpWidgetViewState.Landing;
		const nextHelpView = helpWidgetBackNavigationRoutes[currentHelpView];
		const isCurrentlyOnLandingView = currentHelpView === HelpWidgetViewState.Landing;

		if (isCurrentlyOnLandingView) {
			this.close();
		}

		if (!isCurrentlyOnLandingView) {
			await Router.router?.push({
				pathname: FavoritesRoute.FavoritesListing,
				query: {
					helpView: nextHelpView,
					list: this.favoritesListId,
				},
			});
			this.setCurrentView(nextHelpView);
		}
	}

	async navigateTo(pathname: FavoritesRoute, helpView?: HelpWidgetViewState): Promise<void> {
		const query = buildQueryParams(this.favoritesListId, helpView);

		removeEmptyQueries(query);

		await Router.router?.push({
			pathname,
			query: { ...query },
		});
	}

	handleChatVisibilityChanged(nextVisibility: ChatVisibility): void {
		const isChatCurrentlyMaximized = this.chatVisibility === ChatVisibility.Maximized;
		const isNextChatVisibilityMinimized = nextVisibility === ChatVisibility.Minimized;

		if (isChatCurrentlyMaximized && isNextChatVisibilityMinimized) {
			this.close();
		}

		if (isNextChatVisibilityMinimized) {
			hideChat();
		}

		this.setChatVisibility(nextVisibility);
	}

	openChat = (): void => {
		maximizeChat();

		const trackingData = buildHelpWidgetLinkEventTrackingData('chat');

		this.linkEventStore?.trackLinkEvent(trackingData);
	};

	setFavoritesList = (favoritesList: FavoritesListDetail | null = null): void => {
		this.favoritesList = favoritesList;
	};

	openHelpWidget = (
		nextView: HelpWidgetViewState = HelpWidgetViewState.Landing,
		favoritesList: FavoritesListDetail | null = null,
	): void => {
		this.setFavoritesList(favoritesList);
		this.setIsMobileView(false);
		this.open();
		this.setCurrentView(nextView);
	};

	openHelpWidgetMobileView = async (
		nextView: HelpWidgetViewState,
		favoritesList: FavoritesListDetail | null = null,
	): Promise<void> => {
		this.setFavoritesList(favoritesList);
		this.setIsMobileView(true);
		this.handleBackNavigation();
		await this.navigateTo(FavoritesRoute.FavoritesListing, this.helpViewQueryParam);
		await updateRouterQuery({ helpView: nextView });
		this.setCurrentView(nextView);
		this.open();
	};

	scheduleAppointment = async (): Promise<void> => {
		this.setCurrentView(HelpWidgetViewState.Appointment);

		if (this.isMobileView) {
			await updateRouterQuery<IHelpWidgetQueryParams>({ helpView: HelpWidgetViewState.Appointment });
		}

		const trackingData = buildHelpWidgetLinkEventTrackingData('open schedule an appointment');

		this.linkEventStore?.trackLinkEvent(trackingData);
	};

	openEmailFormFunction = async (): Promise<void> => {
		this.emailFormStore?.registerProcessFormCallback(async () => {
			const sendEmailTrackingData = buildHelpWidgetLinkEventTrackingData('send email');

			this.linkEventStore?.trackLinkEvent(sendEmailTrackingData);

			if (this.isMobileView) {
				await this.navigateTo(FavoritesRoute.FavoritesListing, HelpWidgetViewState.EmailConfirmation);
				this.closeCallbackRef?.();
			}

			this.setCurrentView(HelpWidgetViewState.EmailConfirmation);
		});

		const openEmailTrackingData = buildHelpWidgetLinkEventTrackingData(this.isDAEmail ? 'email your design associate' : 'open email');

		this.linkEventStore?.trackLinkEvent(openEmailTrackingData);

		if (this.favoritesListStore?.list) {
			let shareUrl = this.favoritesListStore.list.sharedFavoritesListUrl;

			if (!this.favoritesListStore.list.sharedFavoritesListUrl) {
				shareUrl = await this.favoritesListStore.shareList(this.favoritesListStore.list) || '';
			}

			this.emailFormStore?.updateFavoriteUrl(shareUrl);
		}

		if (this.isMobileView) {
			await this.navigateTo(FavoritesRoute.FavoritesListing, HelpWidgetViewState.Email);
		}

		this.setCurrentView(HelpWidgetViewState.Email);
	};

	openEmailDAForm = async (): Promise<void> => {
		this.emailFormStore = createHelpWidgetEmailFormStore(this.favoritesListId, this.favoritesListStore?.globalDynamicStore?.model?.userName);

		this.setIsDAEmail(true);

		this.openEmailFormFunction();
	};

	openEmailForm = async (): Promise<void> => {
		this.emailFormStore = createHelpWidgetEmailFormStore(this.favoritesListId);

		this.setIsDAEmail(false);

		this.openEmailFormFunction();
	};

	setHelpViewFromQueryParam = (helpViewQueryParam: HelpWidgetViewState): void => {
		if (helpViewQueryParam && helpViewQueryParam !== HelpWidgetViewState.Landing) {
			this.setIsOpen(true);
			this.setIsExpanded(false);
			this.setIsMinimized(true);
			this.setCurrentView(helpViewQueryParam);
		}
	};

	trackPhoneOptionClick = (): void => {
		const trackingData = buildHelpWidgetLinkEventTrackingData('open 800.301.9720');

		this.linkEventStore?.trackLinkEvent(trackingData);
	};

	trackAppointmentOptionClick = (option: HelpWidgetAppointmentOption): void => {
		const eventName = helpWidgetAppointmentLinkEventNames[option];
		const trackingData = buildHelpWidgetLinkEventTrackingData(eventName);

		this.linkEventStore?.trackLinkEvent(trackingData);
	};
}

export const createHelpWidgetStore = (
	view = HelpWidgetViewState.Landing,
	linkEventStore: LinkEventStore | null = null,
	featureTogglesModel: FeatureTogglesModel | null = null,
	favoritesListStore: FavoritesListStore | null = null,
): HelpWidgetStore => {
	const store = new HelpWidgetStore({ view });

	store.linkEventStore = linkEventStore;
	store.featureTogglesModel = featureTogglesModel;
	store.favoritesListStore = favoritesListStore;

	navToView[store.view](store);

	return store;
};
