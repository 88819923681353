import React from 'react';
import type { GetServerSidePropsContext } from 'next';
import type { AxiosPromise, AxiosResponse } from 'axios';
import {
	model, Model, prop, idProp, modelFlow, _async, _await, modelAction,
} from 'mobx-keystone';

import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { type IFavorites, type IFavoritesCreateListModalData } from '~/favorites/Types/Favorites.interface';
import { modelNamespace } from '~/util/modelNamespace';
import { getAxios } from '~/global/app-config/ipHeaders';
import { setCookieHeader } from '~/util/setCookieHeader';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { FavoritesListSummary } from '~/favorites/Models/FavoritesListSummary.model';
import { createFavoriteSummaries, createFavoritePhotoSummaries } from '~/favorites/list/Factories/FavoritesSummary.factory';
import { CreateNewFavoritesListStore } from '~/favorites/create-new-list/Stores/CreateNewList.store';
import { CreateNewFavoritesList } from '~/favorites/create-new-list/Components/CreateNewFavoritesList';
import { noop } from '~/util/noop';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';

@model(`${modelNamespace.FAVORITES}/FavoritesRootStore`)
export class FavoritesStore extends Model({
	id: idProp,
	designAssociateName: prop<string | undefined>(),
	favoritesAPILink: prop<string>('/api/favorites'),
	error: prop<PromiseError | null>(null).withSetter(),
	lists: prop<FavoritesListSummary[]>(() => []),
	photosLists: prop<FavoritesListSummary[]>(() => []),
	favoritesTrackingEventsStore: prop<FavoritesTrackingEventsStore | undefined>().withSetter(),
	isLoggedIn: prop<boolean>().withSetter(),
}) {
	featureTogglesModel: any;

	magicOverlay: any;

	globalDynamicStore: any;

	globalStaticModel: any;

	magicModal: any;

	HREF: any;

	linkEventStore: any;

	disableFetchingForNow: boolean | undefined;

	onInit() {
		this.magicOverlay = MagicOverlayStoreFactory.create({ position: 'GLOBAL' });
		this.disableFetchingForNow = false;
		// @ts-ignore
		global.store = this;
	}

	@modelFlow
	fetchData = _async(function* (
		this: FavoritesStore,
		pageProps?: AppConfigPageProps | undefined,
		ctx?: GetServerSidePropsContext | undefined
	) {
		const dynaAxios = getAxios(ctx);
		const headers = setCookieHeader(pageProps);

		try {
			let mainPromiseResolver: (value: unknown) => void = noop;
			this.magicOverlay.startLoading(new Promise((resolve) => {
				mainPromiseResolver = resolve;
			}));

			const promise: AxiosPromise = dynaAxios.request({
				url: this.favoritesAPILink,
				method: 'get',
				...(headers) && { headers },
			});

			const response: AxiosResponse<IFavorites> = yield* _await(promise);

			this.processLists(response.data);

			this.designAssociateName = response.data.daName;

			mainPromiseResolver(true);

			return response.data;
		} catch (error: any) {
			if (error?.response?.status !== 404) {
				console.error(error);
			}
			// if (axios.isAxiosError(error)) {
			// 	getRoot(this).onError(new PromiseError(error));
			// }
		}
		return {};
	});

	@modelAction
	processLists(data: IFavorites) {
		const lists = createFavoriteSummaries(data);

		const photosLists = createFavoritePhotoSummaries(data);

		this.lists.length = 0;
		this.lists.push(...lists);

		this.photosLists.length = 0;
		this.photosLists.push(...photosLists);
	}

	@modelAction
	openCreateANewListModal(event: React.MouseEvent, shouldCreateListAndMoveItem = false) {
		const {
			magicModal,
			magicModal: {
				openModal = () => { },
			} = {},
		} = this;

		const onCloseFocusElement = event ? event.target : null;

		const promise = new Promise<IFavoritesCreateListModalData | undefined>((resolve, reject) => {
			try {
				if (magicModal) {
					const newListStore = new CreateNewFavoritesListStore({
						createNewListAPI: '/api/favorites/add-list',
						shouldCreateListAndMoveItem,
					});

					newListStore.featureTogglesModel = this.featureTogglesModel;
					newListStore.openModalResolve = resolve;
					newListStore.openModalReject = reject;

					openModal({
						title: 'Create New List',
						id: 'create-new-list-modal',
						onCloseFocusElement,
						closeModalOnOverlayClick: false,
						showCloseButton: false,
						maxWidth: 500,
						content: {
							children: (
								<CreateNewFavoritesList
									store={newListStore}
								/>
							)
						}
					});
				} else {
					reject('Magic Modal not found');
				}
			} catch (error) {
				console.error(error);
			}
		});

		promise.then(() => {
			this.magicModal.closeModal();
			this.fetchData();
		});

		return promise;
	}

	@modelAction
	onError(error: PromiseError) {
		this.setError(error);
	}

	setFeatureTogglesModel(featureTogglesModel: any) {
		this.featureTogglesModel = featureTogglesModel;
	}

	setGlobalDynamicStore(globalDynamicStore: any) {
		this.globalDynamicStore = globalDynamicStore;
	}

	setGlobalStaticModel(globalStaticModel: any) {
		this.globalStaticModel = globalStaticModel;
	}

	setMagicModal(magicModal: any) {
		this.magicModal = magicModal;
	}

	setHREF(HREF: any) {
		this.HREF = HREF;
	}

	setLinkEventStore(linkEventStore: any) {
		this.linkEventStore = linkEventStore;
	}
}
