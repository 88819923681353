import { ExpiredProductGroup } from '~/favorites/list/Models/ExpiredProductGroup.model';
import { IExpiredProductGroup } from '~/favorites/Types/Favorites.interface';

export const createExpiredProductGroup = (group: IExpiredProductGroup) => {
	const {
		productGroupId,
		salesText,
		sortOrder,
	} = group || {};

	return new ExpiredProductGroup({
		id: productGroupId,
		name: salesText,
		sortOrder,
	});
};

export const createExpiredProductGroups = (groups: IExpiredProductGroup[] = []) => {
	return groups.map((group) => {
		return createExpiredProductGroup(group);
	});
};
