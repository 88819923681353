import { createFavoriteListItem } from '~/favorites/list/Factories/FavoriteListItem.factory';
import { FavoritesListGroup } from '~/favorites/list/Models/FavoriteListGroup.model';
import { FavoritesListItem } from '~/favorites/list/Models/FavoriteListItem.model';
import { FAVORITES_LIST_TYPE, IFavoritesListGroup } from '~/favorites/Types/Favorites.interface';

export const createFavoriteListGroup = (
	group: IFavoritesListGroup,
	listType: FAVORITES_LIST_TYPE,
	items: FavoritesListItem[] = [],
) => {
	const {
		groupId,
		groupName,
		defaultGroup,
		sortOrder,
		itemCount,
		_links: {
			getFavoritesProductGroupAvailability: {
				href: getAvailabilityHref = undefined,
			} = {},
			updateFavoritesProductGroupSorts: {
				href: updateGroupSortHref = undefined,
			} = {},
			updatePhotoSorts: {
				href: updatePhotoSortHref = undefined,
			} = {},
		} = {},
	} = group || {};

	return new FavoritesListGroup({
		id: `list-group-${groupId}`,
		listType,
		name: groupName,
		defaultGroup,
		sortOrder,
		itemCount,
		items,
		getAvailabilityHref,
		updateGroupSortHref: updateGroupSortHref || updatePhotoSortHref,
	});
};

export const createFavoriteListGroups = (groups: IFavoritesListGroup[], listType: FAVORITES_LIST_TYPE) => {
	return groups.map((group: IFavoritesListGroup) => {
		// Favorite lists can be of products or photos
		// renaming productGroups to listItems to be more generic
		if (!group.listItems && group.productGroups) {
			group.listItems = group.productGroups;
		}

		const items = group.listItems ? group.listItems.map((item) => {
			return createFavoriteListItem(item, group.groupId, listType);
		}) : [];

		const listGroup = createFavoriteListGroup(group, listType, items);
		return listGroup;
	});
};
