import React from 'react';
import classNames from 'classnames';

import styles from '~/favorites/help-widget/help-widget-landing-view/HelpWidgetLandingView.module.scss';
import { HelpWidgetContactOption } from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption';
import { ContactOptionVariant } from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption.constants';
import { HelpWidgetTypography } from '~/favorites/help-widget/help-widget-typography/HelpWidgetTypography';

interface IProps {
	/**
	 * Name displayed when there is a design associate
	 * When populated, contact your design associate content is displayed.
	 * When not populated, standard appointment content is displayed.
	 */
	designAssociateName?: string;
	/**
	 * Link href to open in a new window.
	 */
	href?: string;
	/**
	 * When `true`, live chat widget is available and chat button is displayed.
	 * When `false`, live chat widget is unavailable and text indicating chat is currently
	 * not available is displayed.
	 */
	isChatAvailable?: boolean;
	/**
	 * Callback fired when chat button is clicked.
	 */
	onClickChat?: () => void;
	/**
	 * Callback fired when email button is clicked.
	 */
	onClickEmail?: () => void;
	/**
	 * Callback fired when schedule appointment button is clicked.
	 */
	onClickScheduleAppointment: () => void;
	/**
	 * Callback fired when phone number is clicked.
	 */
	onClickPhone: () => void;
	/**
	 * Callback fired when design associate appointment button is clicked.
	 */
	onClickDesignAssociateAppointment?: () => void;
	/**
	 * Callback fired when design associate email button is clicked.
	 */
	onClickDesignAssociateEmail?: () => void;
}

export const HelpWidgetLandingView = (props: IProps) => {
	const {
		designAssociateName = '',
		href = '',
		isChatAvailable = false,
	} = props;

	return (
		<>
			<HelpWidgetTypography
				className={classNames({
					[styles.introText]: true,
					[styles.introTextChatIsUnavailable]: !isChatAvailable,
				})}
				component={'p'}
			>
				Get quick answers to your design questions.
			</HelpWidgetTypography>
			<ul className={classNames(styles.contactOptions, {
				[styles.contactOptionsChatIsUnavailable]: !isChatAvailable
			})}>
				<li>
					<HelpWidgetContactOption
						isChatAvailable={isChatAvailable}
						variant={ContactOptionVariant.Chat}
						onClick={props.onClickChat}
					/>
				</li>
				<li>
					<HelpWidgetContactOption
						variant={ContactOptionVariant.Phone}
						onClick={props.onClickPhone}
					/>
				</li>
				{props.onClickEmail && (
					<li>
						<HelpWidgetContactOption
							variant={ContactOptionVariant.Email}
							onClick={props.onClickEmail}
						/>
					</li>
				)}
			</ul>
			<div className={styles.appointmentSection}>
				<HelpWidgetTypography
					color={'gray'}
					component={'p'}
				>
					Enjoy free, personalized design help in store, virtually or by phone.
				</HelpWidgetTypography>
				<HelpWidgetContactOption
					variant={ContactOptionVariant.Appointment}
					onClick={props.onClickScheduleAppointment}
				/>
			</div>
			{
				designAssociateName && props.onClickDesignAssociateEmail && (
					<div className={styles.designAssociateSection}>
						<HelpWidgetTypography
							className={'tw-font-semibold'}
							color={'gray'}
							component={'p'}
						>
							Contact Your Design Associate
						</HelpWidgetTypography>
						<ul className={styles.contactOptions}>
							{
								href && props.onClickDesignAssociateAppointment && (
									<li>
										<HelpWidgetContactOption
											variant={ContactOptionVariant.DesignAssociateAppointment}
											designAssociateName={designAssociateName}
											href={href}
											onClick={props.onClickDesignAssociateAppointment}
										/>
									</li>
								)
							}
							<li>
								<HelpWidgetContactOption
									variant={ContactOptionVariant.DesignAssociateEmail}
									designAssociateName={designAssociateName}
									onClick={props.onClickDesignAssociateEmail}
								/>
							</li>
						</ul>
					</div>
				)
			}
		</>
	);
};
