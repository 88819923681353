import {
	AppointmentProps, ChatProps, DesignAssociateAppointmentProps, DesignAssociateEmailProps, EmailProps, HelpWidgetContactOptionProps, PhoneProps,
} from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption';
import { ContactOptionVariant } from '~/favorites/help-widget/help-widget-contact-option/HelpWidgetContactOption.constants';

export const isPhone = (props: HelpWidgetContactOptionProps): props is PhoneProps => (
	props.variant === ContactOptionVariant.Phone
);

export const isChat = (props: HelpWidgetContactOptionProps): props is ChatProps => (
	props.variant === ContactOptionVariant.Chat
);

export const isEmail = (props: HelpWidgetContactOptionProps): props is EmailProps => (
	props.variant === ContactOptionVariant.Email
);

export const isAppointment = (props: HelpWidgetContactOptionProps): props is AppointmentProps => (
	props.variant === ContactOptionVariant.Appointment
);

export const isDesignAssociateAppointment = (props: HelpWidgetContactOptionProps): props is DesignAssociateAppointmentProps => (
	props.variant === ContactOptionVariant.DesignAssociateAppointment
);

export const isDesignAssociateEmail = (props: HelpWidgetContactOptionProps): props is DesignAssociateEmailProps => (
	props.variant === ContactOptionVariant.DesignAssociateEmail
);
