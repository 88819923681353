import { ContactIcon } from '~/favorites/help-widget/help-widget-contact-icon/HelpWidgetContactIcon.constants';

export enum ContactOptionVariant {
	Appointment = 'appointment',
	Chat = 'chat',
	DesignAssociateAppointment = 'design-associate-appointment',
	DesignAssociateEmail = 'design-associate-email',
	Email = 'email',
	Phone = 'phone',
}

export const contactOptionDataQa: Record<ContactOptionVariant, string> = {
	[ContactOptionVariant.Appointment]: 'Schedule an appointment',
	[ContactOptionVariant.Chat]: 'Chat',
	[ContactOptionVariant.DesignAssociateAppointment]: 'Schedule an appointment with',
	[ContactOptionVariant.DesignAssociateEmail]: 'EmailDA',
	[ContactOptionVariant.Email]: 'Email',
	[ContactOptionVariant.Phone]: '800.301.9720',
};

export const contactOptionLabels: Record<ContactOptionVariant, string> = {
	[ContactOptionVariant.Appointment]: 'Schedule an appointment',
	[ContactOptionVariant.Chat]: 'Chat',
	[ContactOptionVariant.DesignAssociateAppointment]: 'Schedule an appointment with',
	[ContactOptionVariant.DesignAssociateEmail]: 'Email',
	[ContactOptionVariant.Email]: 'Email',
	[ContactOptionVariant.Phone]: '800.301.9720',
};

export const contactOptionIcons: Partial<Record<ContactOptionVariant, ContactIcon>> = {
	[ContactOptionVariant.Appointment]: ContactIcon.Appointment,
	[ContactOptionVariant.Chat]: ContactIcon.Chat,
	[ContactOptionVariant.DesignAssociateAppointment]: ContactIcon.DesignAssociateAppointment,
	[ContactOptionVariant.Email]: ContactIcon.Email,
	[ContactOptionVariant.Phone]: ContactIcon.Phone,
};
