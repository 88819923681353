import React from 'react';

import styles from '~/favorites/help-widget/help-widget-chat-unavailable/HelpWidgetChatUnavailable.module.scss';

export const HelpWidgetChatUnavailable = () => {
	return (
		<div className={styles.root}>
			<div className={styles.status} />
			<span
				className={styles.statusLabel}
				data-qa={'help-widget-chat-unavailable-status-label'}
			>
				Currently Not Available
			</span>
		</div>
	);
};
