import React from 'react';

import { Button } from '~/components/Buttons/Components/Button';

interface IProps {
	onClose: () => void;
}

export const HelpWidgetEmailConfirmation = (props: IProps) => {
	return (
		<div
			className={'tw-mb-[35px] md:tw-mb-[41px]'}
			role={'status'}
		>
			<p data-qa={'help-widget-email-confirmation-text'}>
				Your email has been sent and will be answered as soon as possible.
			</p>
			<Button
				data-qa={'help-widget-email-confirmation-close-button'}
				onClick={props.onClose}
			>
				Close
			</Button>
		</div>
	);
};
