import { useEffect, useState } from 'react';

import { createHelpWidgetStore, HelpWidgetStore } from '~/favorites/help-widget/HelpWidget.store';
import { HelpWidgetViewState } from '~/favorites/help-widget/HelpWidget.constants';
import {
	IHelpWidgetLinkEventTrackingData, IHelpWidgetQueryParams, IHelpWidgetStoreProps,
} from '~/favorites/help-widget/HelpWidget.type';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

export const useHelpWidgetStore = (helpWidgetStoreProps: IHelpWidgetStoreProps | null = null): HelpWidgetStore => {
	const shouldOpenHelpWidgetOnInit = helpWidgetStoreProps?.shouldOpenHelpWidgetOnInit || false;
	const defaultView = helpWidgetStoreProps?.defaultView || HelpWidgetViewState.Landing;
	const isMobileView = helpWidgetStoreProps?.isMobileView || false;
	const favoritesListStore = helpWidgetStoreProps?.favoritesListStore || null;

	const { linkEventStore, featureTogglesModel } = useGlobalContext();
	const [store] = useState<HelpWidgetStore>(() => createHelpWidgetStore(defaultView, linkEventStore, featureTogglesModel, favoritesListStore));

	useEffect(() => {
		store.init(shouldOpenHelpWidgetOnInit, isMobileView);
	}, []);

	return store;
};

export const buildQueryParams = (
	favoritesListId: string = '',
	helpView: HelpWidgetViewState = HelpWidgetViewState.Landing,
): IHelpWidgetQueryParams => {
	const query: IHelpWidgetQueryParams = { list: favoritesListId };

	if (helpView && helpView !== HelpWidgetViewState.Landing) {
		query.helpView = helpView;
	}

	return query;
};

export const buildHelpWidgetLinkEventTrackingData = (trLinkEventName: string): IHelpWidgetLinkEventTrackingData => {
	return {
		trLinkEventCompName: 'need design help',
		trLinkEventCompType: 'contact modal',
		trLinkEventName,
		trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
	};
};
