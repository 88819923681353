import { computed } from 'mobx';
import {
	getRoot,
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { FavoritesListItem } from '~/favorites/list/Models/FavoriteListItem.model';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { LIST_VIEWS } from '~/favorites/list/Components/ListViewsToolbar';
import { modelNamespace } from '~/util/modelNamespace';
import { FAVORITES_LIST_TYPE } from '~/favorites/Types/Favorites.interface';

@model(`${modelNamespace.FAVORITES}/ListGroup`)
export class FavoritesListGroup extends Model({
	id: idProp.withSetter(),
	defaultGroup: prop<boolean>(),
	listType: prop<FAVORITES_LIST_TYPE>(),
	name: prop<string>(),
	sortOrder: prop<number>(),
	itemCount: prop<number>(),
	items: prop<FavoritesListItem[]>(() => []).withSetter(),
	getAvailabilityHref: prop<string | undefined>(),
	updateGroupSortHref: prop<string | undefined>(),
}) {
	onInit() {
		this.items.sort((a, b) => b.sortOrder - a.sortOrder);
	}

	@computed
	get selectedCount() {
		return this.items.filter(item => item.selected).length;
	}

	@computed
	get groupIds() {
		return this.items.map(item => item.id);
	}

	@computed
	get groupSortHref() {
		return this.updateGroupSortHref;
	}

	@computed
	get showSortArrows() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return Boolean(this.groupSortHref)
			&& this.items.length > 1
			&& (rootStore.listView === LIST_VIEWS.LARGE || rootStore.listView === LIST_VIEWS.DETAIL);
	}
}
