import React from 'react';
import classNames from 'classnames';

import styles from '~/favorites/list/Components/list-views-toolbar.module.scss';

import { useFavoritesListContext } from '~/favorites/list/Contexts/FavoritesList.context';
import { LargeTilesSVG } from '~/favorites/list/Components/LargeTilesSVG';
import { SmallTilesSVG } from '~/favorites/list/Components/SmallTilesSVG';
import { DetailViewSVG } from '~/favorites/list/Components/DetailViewSVG';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const LIST_VIEWS = {
	LARGE: 'list-view-large',
	SMALL: 'list-view-small',
	DETAIL: 'listViewDetail'
};

type ListView = typeof LIST_VIEWS;
type ListViewKey = keyof ListView;
export type ListViewValue = ListView[ListViewKey];

export const ListViewsToolbar = () => {
	const {
		rootStore,
		rootStore: {
			listView,
		} = {},
	} = useFavoritesListContext();

	const {
		globalDynamicStore: {
			model: {
				isCustomerGuest = true,
				isEngageSAPOnlyCustomer = false,
			} = {},
		} = {},
	} = useGlobalContext();

	const viewClickHandler = (view: ListViewValue) => {
		rootStore.setListView(view);
	};

	const detailViewEnabled = !isCustomerGuest && !isEngageSAPOnlyCustomer;

	return (
		<div
			className={classNames(`${styles['list-views-toolbar']} ${listView} `, {
				'sm:tw-hidden': !detailViewEnabled,
			})}
			id="list-views-toolbar"
			data-qa="list-views-toolbar"
		>
			<div className={styles['toolbar-text']} data-qa={`${listView}-selected`}>View:</div>
			<button
				id="list-views-toolbar-button-large"
				className={classNames(styles['toolbar-button'], {
					[styles['selected']]: listView === LIST_VIEWS.LARGE,
				})}
				data-qa="list-views-toolbar-button-large"
				onClick={() => viewClickHandler(LIST_VIEWS.LARGE)}
				type="button"
			>
				<LargeTilesSVG className={classNames({ 'selected': listView === LIST_VIEWS.LARGE })} />
				<span className="tw-sr-only">Large Tiles {listView === LIST_VIEWS.LARGE && ' currently selected'}</span>
			</button>
			<button
				id="list-views-toolbar-button-small"
				className={classNames(`${styles['toolbar-button']} ${styles.smallTileView}`, {
					[styles['selected']]: listView === LIST_VIEWS.SMALL,
				})}
				data-qa="list-views-toolbar-button-small"
				onClick={() => viewClickHandler(LIST_VIEWS.SMALL)}
				type="button"
			>
				<SmallTilesSVG className={classNames({ 'selected': listView === LIST_VIEWS.SMALL })} />
				<span className="tw-sr-only">Small Tiles {listView === LIST_VIEWS.SMALL && ' currently selected'}</span>
			</button>
			{
				detailViewEnabled && !isCustomerGuest && !isEngageSAPOnlyCustomer && (
					<button
						id="list-views-toolbar-button-detail"
						className={classNames(styles['toolbar-button'], {
							[styles['selected']]: listView === LIST_VIEWS.DETAIL
						})}
						data-qa="list-views-toolbar-button-detail"
						onClick={() => viewClickHandler(LIST_VIEWS.DETAIL)}
						type="button"
					>
						<DetailViewSVG className={classNames({ 'selected': listView === LIST_VIEWS.DETAIL })} />
						<span className="tw-sr-only">Detailed View {listView === LIST_VIEWS.DETAIL && ' currently selected'}</span>
					</button>
				)
			}
		</div>
	);
};
