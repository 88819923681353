import { FavoritesListItem } from '~/favorites/list/Models/FavoriteListItem.model';
import { FAVORITES_LIST_TYPE, IFavoritesListItem } from '~/favorites/Types/Favorites.interface';
import { ImageModel } from '~/util/images/Image.model';
import { StatusMessageModel } from '~/util/messaging/line-item-status/StatusMessage.model';
import { ReviewStarsModel } from '~/util/power-reviews/Models/ReviewStars.model';

export const createFavoriteListItem = (item: IFavoritesListItem, groupId: string, listType: FAVORITES_LIST_TYPE) => {
	const {
		altText = undefined,
		productGroupId,
		salesText,
		id,
		fsMediaViewerImage,
		imageUrl,
		maxQuantity,
		productUrl,
		reviews: {
			count: reviewsCount = undefined,
			reviewsAverageOverallRating: starCount = undefined,
		} = {},
		quantity,
		subtotal,
		sortOrder,
		statusMessage: {
			messageKey: statusMessageKey = undefined,
			messageValues: statusMessageValues = undefined,
		} = {},
		unitPrice,
		_links: {
			deleteFavoritePhoto: {
				href: deletePhotoHref = undefined,
			} = {},
			fakeExpiredProductGroup: {
				href: fakeExpireItemHref = undefined,
			} = {},
			updateFavoritesProductGroupQuantity: {
				href: updateQuantityHref = undefined,
			} = {},
			deleteFavoritesProductGroup: {
				href: deleteHref = undefined,
			} = {},
			moveFavoritesProductGroupToCart: {
				href: moveToCartHref = undefined,
			} = {},
			addFavoritesProductGroupToCart: {
				href: addSharedItemToCartHref = undefined,
			} = {},
			moveFavoritesProductGroupToList: {
				href: moveFavoritesProductGroupToListHref = undefined,
			} = {},
			shopThisRoom: {
				href: shopThisRoomUrl = undefined,
			} = {},
		} = {},
	} = item || {};

	const statusMessage = statusMessageKey ? new StatusMessageModel({
		messageKey: statusMessageKey,
		messageValues: statusMessageValues,
	}) : undefined;

	return new FavoritesListItem({
		id: id ? `${id}` : `${productGroupId}`,
		...(altText) && { altText },
		groupId,
		...(imageUrl) && {
			image: new ImageModel({
				imagePath: imageUrl,
				width: listType === FAVORITES_LIST_TYPE.ITEM ? 265 : undefined,
				height: listType === FAVORITES_LIST_TYPE.ITEM ? 150 : 225,
				mediumWidth: listType === FAVORITES_LIST_TYPE.ITEM ? 354 : undefined,
				mediumHeight: listType === FAVORITES_LIST_TYPE.ITEM ? 200 : 335,
			}),
		},
		maxQuantity,
		fsMediaViewerImage,
		name: salesText,
		productUrl,
		quantity,
		...(reviewsCount) && {
			reviewStars: new ReviewStarsModel({
				reviewsCount,
				starCount,
			}),
		},
		sortOrder,
		subtotal,
		statusMessage,
		unitPrice,
		fakeExpireItemHref,
		updateQuantityHref,
		deleteHref: deleteHref || deletePhotoHref,
		moveToCartHref,
		addSharedItemToCartHref,
		moveFavoritesProductGroupToListHref,
		shopThisRoomUrl,
	});
};
