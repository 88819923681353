import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';
import { ContactIcon, contactIconSizes } from '~/favorites/help-widget/help-widget-contact-icon/HelpWidgetContactIcon.constants';

export interface IProps {
	icon: ContactIcon;
}

export const HelpWidgetContactIcon = (props: IProps) => {
	const { height, width } = contactIconSizes[props.icon];

	return (
		<S7Image
			alt={''}
			height={80}
			src={`${s7ContentPath}/${props.icon}`}
			style={{ height, width }}
			width={80}
		/>
	);
};
