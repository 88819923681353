import { computed } from 'mobx';
import {
	getRoot,
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { ExpiredProductGroup } from '~/favorites/list/Models/ExpiredProductGroup.model';

import { FavoritesListGroup } from '~/favorites/list/Models/FavoriteListGroup.model';
import { FavoritesListStore } from '~/favorites/list/Stores/FavoriteList.store.root';
import { IDesignPresentation } from '~/favorites/Types/Favorites.interface';

import { ImageModel } from '~/util/images/Image.model';

import { modelNamespace } from '~/util/modelNamespace';

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

@model(`${modelNamespace.FAVORITES}/ListDetail`)
export class FavoritesListDetail extends Model({
	id: idProp.withSetter(),
	name: prop<string>(),
	image: prop<ImageModel | undefined>(),
	designPresentation: prop<IDesignPresentation | undefined>(),
	defaultList: prop<boolean>(),
	notes: prop<string>(''),
	sortOrder: prop<number>(),
	subtotal: prop<number>(0),
	itemCount: prop<number>(),
	expiredProductGroups: prop<ExpiredProductGroup[]>(() => []),
	groups: prop<FavoritesListGroup[]>(() => []).withSetter(),
	deleteListHref: prop<string | undefined>(),
	renameListHref: prop<string | undefined>(),
	updateNotesHref: prop<string | undefined>(),
	removeExpiredProductGroupsHref: prop<string | undefined>(),
	shareListHref: prop<string | undefined>(),
	sharedFavoritesListUrl: prop<string | undefined>(),
	moveAllProductsCart: prop<string | undefined>(),
	addAllProductsCart: prop<string | undefined>(),
	addDesignPresentationHref: prop<string | undefined>(),
}) {
	onInit() {
		this.groups.sort((a, b) => a.sortOrder - b.sortOrder);
	}

	@computed
	get selectedCount() {
		let count = 0;
		this.groups.forEach((group) => {
			count += group.selectedCount;
		});
		return count;
	}

	get hasItems() {
		return this.itemCount > 0;
	}

	get hasImage() {
		return Boolean(this.image);
	}

	get formattedSubtotal() {
		return formatter.format(this.subtotal);
	}

	get supportsAddAllProductsToCart() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return Boolean(this.addAllProductsCart) && this.itemCount > 1 && rootStore.shareMode;
	}

	get supportsRemovingExpiredProductGroups() {
		return typeof this.removeExpiredProductGroupsHref !== 'undefined';
	}

	get supportsDeletingList() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return typeof this.deleteListHref !== 'undefined' && !rootStore.shareMode;
	}

	get supportsUpdatingNotes() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return typeof this.updateNotesHref !== 'undefined' && !rootStore.shareMode;
	}

	get supportsRenamingList() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return typeof this.renameListHref !== 'undefined' && !rootStore.shareMode;
	}

	get supportsAddDesignPresentation() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return typeof this.addDesignPresentationHref !== 'undefined' && !rootStore.shareMode;
	}

	get supportsSharingList() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return (
			(typeof this.shareListHref !== 'undefined' || typeof this.sharedFavoritesListUrl !== 'undefined')
			&& !rootStore.globalDynamicStore.model.isCustomerGuest
		);
	}

	get supportsMoveAllProductsToCart() {
		const rootStore = getRoot<FavoritesListStore>(this);
		return Boolean(this.moveAllProductsCart) && this.itemCount > 1 && !rootStore.shareMode;
	}

	get hasNotes() {
		return typeof this.notes !== 'undefined' && this.notes.length > 0;
	}
}
