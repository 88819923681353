export enum ChatVisibility {
	Hidden = 'hidden',
	Maximized = 'maximized',
	Minimized = 'minimized'
}

export enum ChatAvailability {
	Offline = 'offline',
	Online = 'online'
}

export enum ChatEvent {
	Ready = 'ready',
	VisibilityChanged = 'visibility_changed',
}

export enum ChatMethod {
	Hide = 'hide',
	Maximize = 'maximize'
}
